import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "../../../components/NotFound";
import Dash from "./dash";
import View from "./view";
import ViewTask from "./viewtask";
import TemplateNavbar from "../_components/TemplateNavbar";
import AreaTasksSettingsViewStep from "./viewstep";

export default function AreaTasksSettings({history, match}) {
  const routePrefix = "/settings/area_tasks";

  return (
    <>
      <TemplateNavbar history={history} match={{...match, url: "/settings"}} />
      <Switch>
        <Route exact path={`${routePrefix}/:id/:task/:step`} component={AreaTasksSettingsViewStep} />
        <Route exact path={`${routePrefix}/:id/:task`} component={ViewTask} />
        <Route exact path={`${routePrefix}/:id`} component={View} />
        <Route exact path={`${routePrefix}`} component={Dash} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
    
  );
}
