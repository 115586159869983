import React, { Fragment, useEffect, useState } from "react";
import {useParams } from "react-router-dom";
import API from "../../api";
import CommonFunctions from "../../CommonFunctions";
import Segment from "../../components/Segment";
import { Button } from "../../components/Forms";
import Loader from "../../components/Loader";
import dayjs from "dayjs";
import { MailIcon, PencilIcon, XCircleIcon } from "@heroicons/react/solid";
import _appConfig from "../../_appConfig";
import { CheckCircleIcon, PaperClipIcon } from "@heroicons/react/outline";
import { Authentication } from "../../plugins/clio_auth";
import { Dialog, Transition } from "@headlessui/react";

export default function View({ history }) {
  const params = useParams();

  const [data, setData] = useState({});

  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    if (mountLoading) {
      API.notifications
        .get(params.notification_id)
        .then((res) => {
          console.log("🚀 ~ file: view.js:36 ~ .then ~ res:", res);
          setData(res);
        })
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setMountLoading(false));
    }
  }, [mountLoading, params]);

  return (
    <div className="">
      <Segment>
        <div className="">
          <h3 className="leading-6 font-medium text-xl text-gray-900 flex justify-between">
            <span className="my-auto">View Notification {data.postbox_type}</span>
            {data.completed && <div>
              {data.status == "Cancelled" || data.status == "Unapproved" ? <XCircleIcon className="h-5 w-5 flex-shrink-0 text-red-400 inline-block" aria-hidden="true" /> :
            <CheckCircleIcon className="h-5 w-5 flex-shrink-0 text-green-400 inline-block" aria-hidden="true" />} {data.status} at {dayjs(data.completedAt).format("DD/MM/YYYY h:mm A")}
            </div>}
            {Authentication.can("notifications.edit") && data.status !== "Cancelled" && !data.completed &&
              <>
                <span className="isolate inline-flex rounded-md shadow-sm">
                  <Button
                    // disabled={true}
                    label="Edit"
                    className="ml-1 text-sm h-10"
                    icon={PencilIcon}
                    colour="warning"
                    onClick={() => history.push(`/notifications/${params.notification_id}/edit`)}
                  />
                </span>
              </>
             }
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
          {mountLoading ? (
            <Loader />
          ) : (
            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      To
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.type === "global" ? "All - Global Message" : data.type === "plf" ? "All Organisations - PLF Message" : data.actionedFor && data.actionedFor.name}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Created
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {dayjs(data.createdAt).format("DD/MM/YYYY")} {data.actionedBy && `by ${data.actionedBy.firstName} ${data.actionedBy.lastName}`}
                    </dd>
                  </div>
                  {Authentication.can("notifications.global") &&
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Type
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.type}
                    </dd>
                  </div>}
                  {Authentication.can("notifications.global") &&
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Active
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.active ? "Yes" : "No"}
                    </dd>
                  </div>}
                </dl>

                <p>&nbsp;</p>
                
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Title
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.title}
                    </dd>
                  </div>
                  {data.type == "message" && 
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Message Read
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.read ? "Yes" : "No"}
                    </dd>
                  </div>}
                </dl>
                <p>&nbsp;</p>

                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Message
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.content}
                    </dd>
                  </div>
                </dl>
                <p>&nbsp;</p>
              </div>
            </div>
          )}
        </div>
      </Segment>
    </div>
  );
}
