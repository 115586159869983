import React from "react";
import dayjs from "dayjs";
import { DataGridPro, GridRowsProp, GridColDef, DataGridProProps, GridRowId} from "@mui/x-data-grid-pro";
import CommonFunctions from "../../../CommonFunctions";
import { Button } from "../../../components/Forms";
import { GiMagnifyingGlass, GiPencil } from "react-icons/gi";
import { LinkIcon } from "@heroicons/react/solid";
import { Grid } from "@mui/material";

export default function FeeTable({ debts, history, onLink }) {
  let amount = 0;
  let total_amount_payments = 0;
  let total_amount = 0;
  let total_paid = 0;
  let total_vat = 0;

  for (let index = 0; index < debts.length; index++) {
    const debt = debts[index];
    if (debt.amount) {
      total_amount = total_amount + debt.amount;
      total_vat = total_vat + (debt.amount * 0.2); //grab from org settings
      total_paid = total_paid + debt.amountPaid;
      // amount = amount + ((debt.amount + debt.amount * 0.2) - (debt.amountPaid ? debt.amountPaid : 0));
      amount = amount + (debt.amount - (debt.amountPaid ? debt.amountPaid : 0)); // VAT is already included
    }

  }
  let total = amount;

  let arr = [];
  let arr_fee = [];

  debts.map((d, i) => {

    arr_fee.push({
      key: `fee_${i}`,
      id: `fee_${i}`,
      ...d,
      selection: 0,
      xeroInvoiceNumber: d.linkedFeeParent ? d.linkedFeeParent.xeroInvoiceNumber : d.xeroInvoiceNumber,
      amount: `£${d.amount ? CommonFunctions.toFormattedFixed(d.amount) : "0.00"}`,
      feeAmount: `£${d.amount ? CommonFunctions.toFormattedFixed(parseFloat(d.amount) - parseFloat(d.amount * 0.2)) : "0.00"}`,
      VATAmount: `£${d.amount ? CommonFunctions.toFormattedFixed(d.amount * 0.2) : "0.00"}`,
      dueDate: dayjs(d.dueDate).isValid() ? dayjs(d.dueDate).format("DD/MM/YYYY") : "-",
      amountPaid: `£${d.amountPaid ? CommonFunctions.toFormattedFixed(d.amountPaid) : "0.00"}`,
      paidDate: dayjs(d.paidDate).isValid() ? dayjs(d.paidDate).format("DD/MM/YYYY") : "-",
      matter: d.matter,
      contact: d.matter && d.matter.contact,
      actions: 0,
      issueDate: dayjs(d.issueDate).isValid() ? dayjs(d.issueDate).format("DD/MM/YYYY") : "-",
      days: d.issueDate && d.status !== "paid" ? dayjs().diff(dayjs(d.issueDate), 'day') : "--",
      payments: d.transactions.length > 0 ? d.transactions : [],
    });

    /*

    d.transactions.map((t,ind) => {
      console.log("🚀 ~ d.transactions.map ~ t:", d)
      total_amount_payments = total_amount_payments + (parseFloat(t.credit) ? parseFloat(t.credit) : 0);

      arr.push({
        key: `fee_${i}_transaction_${ind}`,
        ...t,
        id: t._id, 
        fee: d.reference,
        issueDate: dayjs(t.issueDate).isValid() ? dayjs(t.issueDate).format("DD/MM/YYYY") : "-",
        dueDate: dayjs(t.dueDate).isValid() ? dayjs(t.dueDate).format("DD/MM/YYYY") : "-",
        paidDate: dayjs(t.paidDate).isValid() ? dayjs(t.paidDate).format("DD/MM/YYYY") : "-",
        amount: `£${parseFloat(t.amount) ? CommonFunctions.toFormattedFixed(parseFloat(t.amount)) : 0.00}`,
        amountVAT: `£${parseFloat(t.amountVAT) ? CommonFunctions.toFormattedFixed(parseFloat(t.amountVAT)) : 0.00}`,
        // due: dayjs(t.dateDue).isValid() ? dayjs(t.dateDue).format("DD/MM/YYYY") : "-",
        // credit: `£${parseFloat(t.credit) ? CommonFunctions.toFormattedFixed(parseFloat(t.credit)) : 0.00}`,
      });
    }); */

  });

  // const fee_rows: GridRowsProp = arr_fee.length > 0 ? arr_fee.sort((a,b) => a.issueDate > b.issueDate ? 1 : -1) : [];
  const fee_rows: GridRowsProp = arr_fee;

  const fee_cols: GridColDef[] = [
    { headerName: "", field: "selection", width: 100, pinnable: false,
      renderCell: (params) => {
        const d = params.row;
        return <>
         {!d.xeroInvoiceNumber && !d.linkedFeeParent && d.matter && d.matter.xero_send !== "instant" ?
            <input
                id={"link_"+d.id}
                name={"link_"+d.id}
                type="checkbox"
                onChange={() => {
                  onLink(d.id);
                }}
                
                checked={d.link}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            /> : d.linkedFeeParent ? <><LinkIcon className="w-7 text-action" /> {d.linkedFeeParent.reference}</> : ""}
        </>;
      }
    },
    { headerName: "PLF Invoice Number", field: "invoice_num", width: 200, pinnable: false },
    { headerName: "Fee Type", field: "reference", width: 250, pinnable: false },
    { headerName: "Issue Date", field: "issueDate", width: 100, pinnable: false },
    { headerName: "Days Not Paid", field: "days", width: 150, pinnable: false },
    { headerName: "XERO Invoice No", field: "xeroInvoiceNumber", width: 200, pinnable: false, 
      renderCell: (params) => {
        const d = params.row;
        return <>
        {d.linkedFeeParent ? d.linkedFeeParent.xeroInvoiceNumber : d.xeroInvoiceNumber}
                
          <Button 
            disabled={!d.xeroInvoiceURL}
            className="ml-3"
            style={{display: "inline-block"}}
            icon={GiMagnifyingGlass}
            onClick={() =>  window.open(d.xeroInvoiceURL, '_blank').focus()}
            colour={"action"} />
        </>;
      }
    },
    { headerName: "XERO Status", field: "xeroStatus", width: 100, pinnable: false },
    { headerName: "Amount Due", field: "amount", width: 100, pinnable: false },
    { headerName: "Fee Amount", field: "feeAmount", width: 100, pinnable: false },
    { headerName: "Amount VAT", field: "VATAmount", width: 100, pinnable: false },
    { headerName: "Due Date", field: "dueDate", width: 100, pinnable: false },
    { headerName: "Amount Paid", field: "amountPaid", width: 100, pinnable: false },
    { headerName: "Paid Date", field: "paidDate", width: 100, pinnable: false },
    { headerName: "Status", field: "status", width: 100, pinnable: false },
    { headerName: "Matter", field: "matter", width: 150, pinnable: false, renderCell: (params) => {
      const d = params.row;
      return <>
        {d.matter && d.matter.display_number}
      </>;
    } },
    { headerName: "Client", field: "contact", width: 200, pinnable: false, renderCell: (params) => {
      const d = params.row;
      return <>
        {d.contact && d.contact.name}
      </>;
    }  },
    /*
    { headerName: "", field: "actions", width: 100, pinnable: false,  
      renderCell: (params) => {
        const d = params.row;
        return <>
          <Button 
            disabled={!d.matter}
            icon={GiPencil}
            colour={"action"}
            onClick={() =>
              history.push(
                `/matters/${d.matter && d.matter._id}/fees/${d._id}`
              )
            }
          />
        </>;
      } 
    },*/
  ];

  const columnsWithFooter = fee_cols.map((column) => {
    if (column.field === 'credit') {
      return {
        ...column,
        footerName: 'Total',
        footerRenderer: () => total_amount_payments,
      };
    }
    return column;
  });

  const getDetailPanelHeight: DataGridProProps['getDetailPanelHeight'] = (params) => {
    const contentLength = params.row.payments.length || 0;

    let height = Math.min(500, Math.max(200, contentLength * 45))
    
    return height; // Min 100px, max 500px
  };

  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = ({
    row,
  }) => {
    let total = 0;
    let totalVAT = 0;
      return(<div className={"p-3"}>
        <h3>Payments:</h3>
        <Grid container sx={{ display: "flex", gap: 2, alignItems: "center" }} className={"mb-2"}>
          <Button 
            className={"inline-block"}
            label="View Fee"
            disabled={!row.matter}
            icon={GiPencil}
            colour={"action"}
            onClick={() =>
              history.push(
                `/matters/${row.matter && row.matter._id}/fees/${row._id}`
              )
            }
          />
          <Button 
            className={"inline-block"}
            label="View Matter"
            disabled={!row.matter}
            icon={GiPencil}
            colour={"action"}
            onClick={() =>
              history.push(
                `/matters/${row.matter && row.matter._id}`
              )
            }
          />
          <Button 
            className={"inline-block"}
            label="View Contact"
            disabled={!row.contact}
            icon={GiPencil}
            colour={"action"}
            onClick={() =>
              history.push(
                `/contacts/${row.contact && row.contact._id}`
              )
            }
          />
          </Grid>
        <table className=" w-full  border border-gray-400">
          <thead>
            <tr className="bg-gray-200 text-gray-600 divide-x divide-gray-300">
              <th>Payment Type</th>
              <th>Number</th>
              <th>Fee Type</th>
              <th>Issue Date</th>
              <th>Total Amount Paid</th>
              <th>VAT</th>
              <th>Due Date</th>
              <th>Paid Date</th>
            </tr>
          </thead>
          <tbody>
            {row.payments.length > 0 ? row.payments.map(t => {
              total = total + parseFloat(t.amount);
              totalVAT = totalVAT + parseFloat(t.amountVAT);
              return (
                <tr>
                    <td>{t.paymentType}</td>
                    <td>{t.number}</td>
                    <td>{t.reference}</td>
                    <td>{dayjs(t.issueDate).isValid() ? dayjs(t.issueDate).format("DD/MM/YYYY") : "-"}</td>
                    <td>{`£${parseFloat(t.amount) ? CommonFunctions.toFormattedFixed(parseFloat(t.amount)) : 0.00}`}</td>
                    <td>{`£${parseFloat(t.amountVAT) ? CommonFunctions.toFormattedFixed(parseFloat(t.amountVAT)) : 0.00}`}</td>
                    <td>{dayjs(t.dueDate).isValid() ? dayjs(t.dueDate).format("DD/MM/YYYY") : "-"}</td>
                    <td>{dayjs(t.paidDate).isValid() ? dayjs(t.paidDate).format("DD/MM/YYYY") : "-"}</td>
                </tr>
              );
            }) : <tr><td colSpan={5}>No Payments for Fee!</td></tr>}
          </tbody>
          <tfoot>
            <th colSpan={3}>&nbsp;</th>
            <th>Totals:</th>
            <th>{`£${parseFloat(total) ? CommonFunctions.toFormattedFixed(parseFloat(total)) : 0.00}`}</th>
            <th>{`£${parseFloat(totalVAT) ? CommonFunctions.toFormattedFixed(parseFloat(totalVAT)) : 0.00}`}</th>
            <th colSpan={3}>&nbsp;</th>
          </tfoot>
        </table>
        </div>
      )
    };

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState([]);

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    (newIds: GridRowId[]) => {
      setDetailPanelExpandedRowIds(
        newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
      );
    },
    [],
  );

  return (
    <>
    {/* 
      <table className=" w-full  border border-gray-400">
        <thead>
          <tr className="bg-gray-200 text-gray-600 divide-x divide-gray-300">
            <th className="font-medium  ">&nbsp;</th>
            <th className="font-medium  ">PLF Invoice Number</th>
            <th className="font-medium  ">Fee Type</th>
            <th className="font-medium  ">Issue Date</th>
            <th className="font-medium  ">Days Not Paid</th>
            <th className="font-medium ">XERO Invoice No</th>
            <th className="font-medium ">XERO Status</th>
            {/* <th className="font-medium ">Debtors</th> *-/}
            <th className="font-medium  ">Amount Due</th>
            <th className="font-medium  ">Fee Amount</th>
            <th className="font-medium  ">Amount VAT</th>
            <th className="font-medium  ">Due Date</th>
            <th className="font-medium  ">Amount Paid</th>
            <th className="font-medium  ">Paid Date</th>
            <th className="font-medium  ">Status</th>
            <th className="w-1/6 font-medium ">Fee Balance </th>
            <th className="font-medium ">&nbsp;</th>
          </tr>
        </thead>
        <tbody className="p-2 divide-y divide-gray-300 ">
          <tr className="p-2 divide-x divide-gray-300 bg-gray-200">
            <td colSpan={7}>&nbsp;</td>
            <td className="text-right p-2 border-r border-gray-400">
              Total Due: £{CommonFunctions.toFormattedFixed(total_amount)}
            </td>
            <td className="text-right p-2 border-r border-gray-400">
              Total Fee: £{CommonFunctions.toFormattedFixed(total_amount - total_vat)}
            </td>
            <td className="text-right p-2 border-r border-gray-400">
              Total VAT: £{CommonFunctions.toFormattedFixed(total_vat)}
            </td>
            <td>&nbsp;</td>
            <td className="text-right p-2 border-r border-gray-400">
              Total Paid: £{CommonFunctions.toFormattedFixed(total_paid)}
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td className="w-1/6 p-2 text-right">Total Balance: £{CommonFunctions.toFormattedFixed(total)}</td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>

       */}

      <DataGridPro 
          pagination
          pageSizeOptions={[10, 25, 50]}
          rows={fee_rows} 
          columns={fee_cols} 
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
            sorting: {
              sortModel: [{ field: 'matter', sort: 'asc' }],
            },
          }}

          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          detailPanelExpandedRowIds={detailPanelExpandedRowIds}
          onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}

      />
    </>
    
  );
}
