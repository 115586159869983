import React, { useState } from "react";
import ProspectiveContactReceiver from "../../contexts/prospective_contact_context/Prospective_Contact_Receiver";
import ChainedContext from "../../contexts/chainedContext";
import API from "../../api";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import { Button, Input } from "../../components/Forms";
import Segment from "../../components/Segment";
import { CheckIcon, PencilIcon, XIcon } from "@heroicons/react/solid";
import ProspectsBreadcrumbs from "./components/ProspectsBreadcrumbs";

function Settings({ contact }) {
  const [isOpen, setIsOpen] = useState(false);
  const [field, setField] = useState("");
  const [value, setValue] = useState("");
  const [unedited, setUnedited] = useState(true);

  const UpdateSettings = () => {
    let data = {
      [field]: value,
    };
    API.prospective_contact
      .updateSettings(contact._id, data)
      .then(() => {
        toast.success(`${field} setting updated.`);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        contact._refresh();
        setIsOpen(false);
      });
  };

  const SettingDialog = () => {
    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed z-10 inset-0 overflow-y-auto"
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className=" inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="text-xl">
                <Dialog.Title>
                  Updating: <span className="text-primary italic">{field}</span>{" "}
                </Dialog.Title>
              </div>
              <div className="flex flex-col px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                <div className="flex flex-row gap-2">
                  <label className="text-primary p-2 ">{field} : </label>
                  <Input
                    name={field}
                    value={
                      value === "" && unedited === true
                        ? contact.settings[field]
                          ? contact.settings[field]
                          : ""
                        : value
                    }
                    onChange={(e) => {
                      setValue(e.target.value);
                      setUnedited(false);
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-between mt-4 border-t border-gray-300 pt-4">
                <Button
                  colour="alert"
                  label="Cancel"
                  onClick={() => setIsOpen(false)}
                  icon={XIcon}
                />
                <Button
                  colour="positive"
                  label="Confirm"
                  onClick={UpdateSettings}
                  icon={CheckIcon}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div>
      <ProspectsBreadcrumbs panes={[
        { name: `Prospect - #${contact && `${contact.name ? contact.name : `${contact.first_name} ${contact.last_name}`} ${contact.suffix ? contact.suffix : ""}`}`, href: `/prospective-contacts/${contact._id}`, current: false },
        { name: `Settings`, current: true }
      ]} />
      {SettingDialog()}
      <Segment className="mt-4">
        <div className="divide-y divide-gray-300">
          <div className="space-y-1">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Payment Settings
            </h3>
            <p className="max-w-2xl text-sm text-gray-500">
              Change the company settings related to terms of payment
            </p>
          </div>
          <div className="mt-2">
            <dl className="divide-y divide-gray-300 w-full">
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 my-auto">
                  Payment Terms
                </dt>
                <dd className="my-auto text-sm text-gray-900">
                  <span>{contact.settings.paymentTerms} Days</span>
                </dd>
                <dd>
                  <Button
                    onClick={() => {
                      setIsOpen(true);
                      setField("paymentTerms");
                    }}
                    label="Update"
                    className="block ml-auto"
                    colour="action"
                    icon={PencilIcon}
                  />
                </dd>
              </div>
            </dl>
          </div>
          <div className="mt-2">
            <dl className="divide-y divide-gray-300 w-full">
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 my-auto">
                  Default Letter Salutation
                </dt>
                <dd className="my-auto text-sm text-gray-900">
                  <span>{contact.settings.letterSalutation}</span>
                </dd>
                <dd>
                  <Button
                    onClick={() => {
                      setIsOpen(true);
                      setField("letterSalutation");
                    }}
                    label="Update"
                    className="block ml-auto"
                    colour="action"
                    icon={PencilIcon}
                  />
                </dd>
              </div>
            </dl>
          </div>
          <div className="mt-2">
            <dl className="divide-y divide-gray-300 w-full">
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 my-auto">
                  Interest Rate
                </dt>
                <dd className="my-auto text-sm text-gray-900">
                  <span>{contact.settings.interest_rate ? contact.settings.interest_rate : `Default PLC rate of 1.1` }</span>
                </dd>
                <dd>
                  <Button
                    onClick={() => {
                      setIsOpen(true);
                      setField("interest_rate");
                    }}
                    label="Update"
                    className="block ml-auto"
                    colour="action"
                    icon={PencilIcon}
                  />
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </Segment>
    </div>
  );
}
export default ChainedContext(Settings, [
  [
    ProspectiveContactReceiver,
    (contact) => {
      return { contact };
    },
  ],
]);
