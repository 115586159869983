import React, { useState, useEffect } from "react";
import { Button, DateSelect, Input } from "../../components/Forms";
import API from "../../api";
import Segment from "../../components/Segment";
import CommonFunctions from "../../CommonFunctions";
import Loader from "../../components/Loader";
import FeeSchedule from "./_components/FeeTable";
import toast, { LoaderIcon } from "react-hot-toast";
import dayjs from "dayjs";
import { XIcon } from "@heroicons/react/outline";
export default function FeesList({ history, match }) {
  const [fees, setFees] = useState([]);
  const [search, setSearch] = useState("");
  const [rangeFrom, setRangeFrom] = useState("");
  const [rangeTo, setRangeTo] = useState("");
  const [mountLoading, setMountLoading] = useState(true);
  const [syncingXero, setSyncingXero] = useState(false);
  const [orgXero, setOrgXero] = useState(false);

  const getData = () => {
    API.organisations.getMyOrg().then(o => setOrgXero(o._xeroTenantId ? true : false));
  }

  const getFees = () => {
    API.organisations.getAllMyOrgFees(search, rangeFrom, rangeTo).then((fees) => {
      setFees(fees);
    })
    .finally(() => setMountLoading(false))
    .catch((e) => CommonFunctions.handleError(e));
  }

  useEffect(() => {
      getData();
      getFees();      
  }, []);
  useEffect(() => {
      console.log("🚀 ~ file: dash.js:34 ~ useEffect ~ useEffect:")
      getFees();      
  }, [search, rangeFrom, rangeTo]);

  return (
    <div className="">
      <Segment>
        {mountLoading ? (
          <Loader />
        ) : (
          <>           
            <div className="md:grid md:grid-cols-7 md:gap-30 pt-3 pb-4">
              <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                <div className="text-3xl">£{CommonFunctions.toFormattedFixed(fees.reduce((acc, fee) => acc + (fee.amount ? fee.amount : 0), 0))}</div>
                <div className="text-xl text-primary">Total Amount Fees (inc. VAT)</div>
              </div>
              <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                <div className="text-3xl">£{CommonFunctions.toFormattedFixed(fees.reduce((acc, fee) => acc + (fee.amount ? fee.amount * 0.2 : 0), 0))}</div> {/*grab from org settings */}
                <div className="text-xl text-primary">Total VAT</div>
              </div>
              <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                <div className="text-3xl">£{CommonFunctions.toFormattedFixed(fees.reduce((acc, fee) => acc + (fee.amount ? (parseFloat(fee.amount) - parseFloat(fee.amount * 0.2)) : 0), 0))}</div>
                <div className="text-xl text-primary">Total Amount Fees (ex. VAT)</div>
              </div>
              <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                <div className="text-3xl">£{CommonFunctions.toFormattedFixed(fees.reduce((acc, fee) => acc + (fee.amountPaid ? fee.amountPaid : 0), 0))}</div>
                <div className="text-xl text-primary">Total Fees Paid</div>
              </div>
              <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                {/* <div className="text-3xl">£{CommonFunctions.toFormattedFixed(fees.reduce((acc, fee) => acc + ((parseFloat(fee.amount) + parseFloat(fee.amount * 0.2)) - (fee.amountPaid ? fee.amountPaid : 0)), 0))}</div> */}
                <div className="text-3xl">£{CommonFunctions.toFormattedFixed(fees.reduce((acc, fee) => acc + ((parseFloat(fee.amount)) - (fee.amountPaid ? fee.amountPaid : 0)), 0))}</div>
                <div className="text-xl text-primary">Amount Left</div>
              </div>
              <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                <div className="text-3xl">{fees.length}</div>
                <div className="text-xl text-primary">Fees Charges</div>
              </div>
              <div className="text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                <Button
                  label={!orgXero ? "Xero not linked!" : (syncingXero ? <>Sending to Xero{" "}<LoaderIcon className="h-5 w-5" /></> : "Sync Xero")}
                  disabled={syncingXero || !orgXero }
                  colour="warning"
                  onClick={() => { 
                    
                    setSyncingXero(true);
                    toast.custom("Syncing All Matters Xero, this might take some time!")

                    API.organisations.syncAllMattersXero().then(res => {
                      setSyncingXero(false);
                      if(res.success){
                        toast.success(res.message);
                        getData(); 
                      }else{
                        toast.error(res.message);
                      }
                    } ).catch(err => { 
                      setSyncingXero(false);
                      console.log("🚀 ~ API.matters.syncXero ~ err:", err)
                      toast.error("Server error") 
                    })
                
                }
                
                }
                  className="mx-auto mb-2"
                />
                <Button
                  label={!orgXero ? "Xero not linked!" : (syncingXero ? <>Sending to Xero{" "}<LoaderIcon className="h-5 w-5" /></> : "Send to Xero")}
                //   disabled={syncingXero || !orgXero || matter.xero_send === "instant" } // || !fees.filter(f => f.link == true).length > 0
                    disabled={true}
                  colour="warning"
                  onClick={() => {                     
                    /* setSyncingXero(true);

                    API.matters.syncLinkXero(matter._id, fees.filter(f => f.link == true)).then(res => {
                      
                      setSyncingXero(false);
                      if(res.success){
                        toast.success(res.message);
                        getData(); 
                      }else{
                        toast.error(res.message);
                      }
                    } ).catch(err => { 
                      setSyncingXero(false);
                      console.log("🚀 ~ API.matters.syncXero ~ err:", err)
                      toast.error("Server error") 
                    }) */
                
                }
                
                }
                  className="mx-auto mb-2"
                />
                {/* {matter.xero_send === "instant" && <i>Matter Xero settings is set to instant so Invoices are created on Xero soon as created unable to link before send!</i>} */}
                {/* <p>Last Synced: {matter.lastSync ? dayjs(matter.lastSync).toString() : "Not Synced with Xero!"}</p> */}
              </div>
            </div>

            <div className="md:grid md:grid-cols-4 md:gap-3 pt-3 pb-4">
              <div className="col-span-2">
                <Input 
                  label="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <Button icon={XIcon} label={"Clear All"} onClick={() => {setRangeFrom(""); setRangeTo(""); setSearch("");}} />
              </div>

              <div>
                <DateSelect
                  required
                  label="Issued From"
                  name="rangeFrom"
                  value={rangeFrom}
                  onChange={(date) => setRangeFrom(date)}
                />
                <Button icon={XIcon} onClick={() => setRangeFrom("")} />
              </div>

              <div>
                <DateSelect
                  required
                  label="Issued To"
                  name="rangeTo"
                  value={rangeTo}
                  onChange={(date) => setRangeTo(date)}
                />
                <Button icon={XIcon} onClick={() => setRangeTo("")} />
              </div>

              
            </div>
            <FeeSchedule
              debts={fees}
            //   editData={handleEdit}
              onLink={(inx) => {
                console.log("🚀 ~ file: dash.js:132 ~ FeesList ~ inx:", inx);
                return;
                let temp = fees;

                if(temp[inx].link == undefined) { 
                  temp[inx].link = false;
                }

                temp[inx].link = !temp[inx].link;
                setFees(temp);
              }}
              match={match}
              history={history}
            />
          </>
        )}
      </Segment>
    </div>
  );
}
