import React, { useEffect, useState } from "react";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/solid";
import DatePicker from "react-datepicker";
import DayJS from "dayjs";
import { Button, Input } from "../../../../components/Forms";
import ConfirmModal from "../../../../components/Modals/ConfirmModal";

const BaseRatesTable = ({ rates, onCreate, onUpdate, onDelete = () => {} }) => {
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    date: new Date(),
    rate: "1",
  });
  const [submitError, setSubmitError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    let errors = {};
    if (parseFloat(formData.rate) < 0.01) {
      errors.rate = "Rate must be grater then 0.01";
    }
    if (formData.rate === "") {
      errors.rate = "Rate can not be empty";
    }

    if (formData.date === null) {
      errors.date = "Date can not be empty";
    }

    setFormErrors(errors);
  }, [formData]);

  const editing = formData._id !== undefined;

  const handleDateOnChange = (date) => {
    setFormData({
      ...formData,
      date,
    });
  };
  const handleRateOnChange = (e) => {
    if (e.target.value.match(/^\d+(\.\d{0,2})?$/g) || e.target.value === "") {
      setFormData({
        ...formData,
        rate: e.target.value + "",
      });
    }
  };
  const handleFormSubmit = () => {
    setFormIsSubmitting(true);
    setSubmitError(null);
    let apiAction;
    if (formData._id === undefined) {
      // Create new
      apiAction = onCreate(formData);
    } else {
      // Editing
      apiAction = onUpdate(formData._id, formData);
    }
    apiAction
      .then(() => {
        setFormData({
          date: new Date(),
          rate: "1",
        }); // Reset the form
      })
      .catch((err) => {
        if (err.isAxiosError !== undefined) {
          setSubmitError(err.response.data.message);
        } else {
          setSubmitError(
            "There was an unexpected error while processing data."
          );
        }
      })
      .finally(() => {
        setFormIsSubmitting(false);
      });
  };
  const handleDeleteSubmit = () => {
    setShowDelete(false);
    setFormIsSubmitting(true);
    setSubmitError(null);
    onDelete(formData._id)
      .then(() => {
        setFormData({
          date: new Date(),
          rate: "1",
        }); // Reset the form
      })
      .catch((err) => {
        if (err.isAxiosError !== undefined) {
          setSubmitError(err.response.data.message);
        } else {
          setSubmitError(
            "There was an unexpected error while processing data."
          );
        }
      })
      .finally(() => {
        setFormIsSubmitting(false);
      });
  };

  return (
    <div>
      {submitError && (
        <div
          className={"p-2 border rounded border-red-800 bg-red-700 text-white"}
        >
          {submitError}
        </div>
      )}
      {showDelete && (
        <ConfirmModal
          handleClose={() => setShowDelete(false)}
          open={true}
          header={"Are you sure?"}
          content={
            "Are you sure you like to remove this base rate, this action can't be undone."
          }
          handleSubmit={handleDeleteSubmit}
        />
      )}

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Current Rate
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      <DatePicker
                        onChange={handleDateOnChange}
                        selected={formData.date}
                        showPopperArrow={false}
                        shouldCloseOnSelect={true}
                        dateFormat="dd-MM-yy"
                        maxDate={new Date()}
                        disabled={formIsSubmitting}
                        className={`relative w-full p-2 rounded-lg bg-gray-100 border ${
                          formErrors.date ? "border-red-400" : "border-gray-300"
                        }`}
                      />
                      {formErrors.date && (
                        <span className={"p-1 text-red-500"}>
                          {formErrors.date}
                        </span>
                      )}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <Input
                        name="rate"
                        value={formData.rate}
                        onChange={handleRateOnChange}
                        error={formErrors.rate}
                        disabled={formIsSubmitting}
                      />
                      {formErrors.rate && (
                        <span className={"p-1 text-red-500"}>
                          {formErrors.rate}
                        </span>
                      )}
                    </th>
                    <th scope="col" className="py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="isolate inline-flex rounded-md shadow-sm">
                        {editing && (
                          <Button
                            className="mr-4"
                            label={""}
                            colour={"alert"}
                            icon={TrashIcon}
                            disabled={
                              Object.keys(formErrors).length > 0 ||
                              formIsSubmitting
                            }
                            onClick={() => {
                              setShowDelete(true);
                            }}
                          />
                        )}
                        <Button
                          label={""}
                          colour={editing ? "warning" : "positive"}
                          icon={editing ? PencilIcon : PlusIcon}
                          className={"ml-auto"}
                          disabled={
                            Object.keys(formErrors).length > 0 ||
                            formIsSubmitting
                          }
                          onClick={handleFormSubmit}
                        />
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {rates.length ? (
                    rates.map((rate, index) => (
                      <tr key={"rate_" + index}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {DayJS(rate.date).format("DD/MM/YYYY")}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {rate.rate}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <Button
                            label={""}
                            colour={"action"}
                            icon={PencilIcon}
                            className={"ml-auto"}
                            onClick={() => {
                              setFormData({
                                _id: rate._id,
                                date: new Date(rate.date),
                                rate: rate.rate,
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={3}
                        className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                      >
                        There are no items to display.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BaseRatesTable;
