import React from "react";
import DayJS from "dayjs";
// import { position } from "tailwindcss/lib/util/dataTypes";

const DirectorsList = ({ directors }) => {
  return (
    <div className="grid grid-cols-2  gap-2 ">
      {directors.map((director) => {
        return (
          <div
            className={"rounded-xl p-2 mb-2 border-2 border-gray-500"}
            key={director.id}
          >
            <p>Name: {director.name}</p>
            {director.nationality !== undefined && (
              <p>Nationality: {director.nationality}</p>
            )}
            {director.gender !== undefined && <p>Gender: {director.gender}</p>}
            {director.dateOfBirth !== undefined && (
              // <p>DOB: {DayJS(director.dateOfBirth).format("DD/MM/YYYY")}</p>
              <p>DOB: {DayJS(director.dateOfBirth).format("MMMM YYYY")}</p>
            )}
            {director.address !== undefined && (
              <p>Address: {director.address.simpleValue}</p>
            )}
            {director.additionalData &&
              director.additionalData.honours !== undefined && (
                <p>Honours: {director.additionalData.honours}</p>
              )}

            {director.positions.length !== 0 && (
              <React.Fragment>
                <div className="font-medium p-2 text-primary">Positions</div>
                <table>
                  <thead>
                    <tr>
                      <th>Appointed</th>
                      <th>Position</th>
                    </tr>
                  </thead>
                  <tbody>
                    {director.positions.map((position, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {DayJS(position.dateAppointed).format("DD/MM/YYYY")}
                          </td>
                          <td>{position.positionName}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </React.Fragment>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default DirectorsList;
