import React, { Fragment, useEffect, useState } from "react";
import {useParams } from "react-router-dom";
import API from "../../api";
import CommonFunctions from "../../CommonFunctions";
import Segment from "../../components/Segment";
import { Button, DateSelect, Input, TextArea, Toggle } from "../../components/Forms";
import Loader from "../../components/Loader";
import dayjs from "dayjs";
import { DocumentIcon, MailIcon, PencilIcon, XCircleIcon } from "@heroicons/react/solid";
import _appConfig from "../../_appConfig";
import { CheckCircleIcon, PaperClipIcon } from "@heroicons/react/outline";
import { Authentication } from "../../plugins/clio_auth";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";

export default function Edit({history}) {
  const params = useParams();

  const [data, setData] = useState({});

  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    if (mountLoading) {
      API.notifications
        .get(params.notification_id)
        .then((res) => {
          console.log("🚀 ~ file: view.js:36 ~ .then ~ res:", res);
          setData(res);
        })
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setMountLoading(false));
    }
  }, [mountLoading, params]);

  return (
    <div className="">
      <Segment>
        <div className="">
          <h3 className="leading-6 font-medium text-xl text-gray-900 flex justify-between">
            <span className="my-auto">Edit Notification {data.postbox_type}</span>
            {data.completed && <div>
              {data.status == "Cancelled" || data.status == "Unapproved" ? <XCircleIcon className="h-5 w-5 flex-shrink-0 text-red-400 inline-block" aria-hidden="true" /> :
            <CheckCircleIcon className="h-5 w-5 flex-shrink-0 text-green-400 inline-block" aria-hidden="true" />} {data.status} at {dayjs(data.completedAt).format("DD/MM/YYYY h:mm A")}
            </div>}
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
          {mountLoading ? (
            <Loader />
          ) : (
            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      To
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.type === "global" ? "All - Global Message" : data.type === "plf" ? "All Organisations - PLF Message" : data.actionedFor && data.actionedFor.name}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Created
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {/* <DateSelect value={data.createdAt} /> */}
                      {dayjs(data.createdAt).format("DD/MM/YYYY")} {data.actionedBy && `by ${data.actionedBy.firstName} ${data.actionedBy.lastName}`}
                    </dd>
                  </div>
                  {Authentication.can("notifications.global") &&
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Type
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {/* {Authentication.can("notifications.global")} */}

                      <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">          
                        <div className="sm:col-span-2 sm:mt-0">
                          <div className="max-w-lg">
                            <div className="mt-6 space-y-6">
                              <div className="flex items-center gap-x-3">
                                <input
                                  id="type_message"
                                  name="type"
                                  type="radio"
                                  className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
                                  checked={data.type == "message"}
                                  onChange={() => setData({...data, type: "message"}) }
                                />
                                <label htmlFor="type_message" className="block text-sm font-medium leading-6 text-gray-900">
                                  Message
                                </label>
                              </div>
                              //Admin Only
                              <div className="flex items-center gap-x-3">
                                <input
                                  id="type_global"
                                  name="type"
                                  type="radio"
                                  className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
                                  checked={data.type == "global"}
                                  onChange={() => setData({...data, type: "global"}) }
                                />
                                <label htmlFor="type_global" className="block text-sm font-medium leading-6 text-gray-900">
                                  Global Notification
                                </label>
                              </div>
                              //PLF Admin only
                              <div className="flex items-center gap-x-3">
                                <input
                                  id="type_plf"
                                  name="type"
                                  type="radio"
                                  className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
                                  checked={data.type == "plf"}
                                  onChange={() => setData({...data, type: "plf"}) }
                                />
                                <label htmlFor="type_plf" className="block text-sm font-medium leading-6 text-gray-900">
                                  PLF System Notification
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </dd>
                  </div>}
                  {Authentication.can("notifications.global") &&
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Active
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <Toggle enabled={data.active} onChange={() => setData({ ...data, active: data.active ? !data.active : true})} />
                    </dd>
                  </div>}
                </dl>

                <p>&nbsp;</p>
                
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Title
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <Input type="title" value={data.title} onChange={(e) => setData({ ...data, title: e.target.value })} />
                    </dd>
                  </div>
                  {data.type == "message" && 
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Message Read
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.read ? "Yes" : "No"}
                    </dd>
                  </div>}
                </dl>
                <p>&nbsp;</p>

                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Message
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <TextArea value={data.content} onChange={(e) => setData({ ...data, content: e.target.value })} />
                    </dd>
                  </div>
                </dl>
                <p>&nbsp;</p>
                <span className="isolate inline-flex rounded-md shadow-sm">
                  <Button
                    onClick={() => {
                      API.notifications.update(params.notification_id, data).then((res) => {
                        if(res.success){
                          history.push(`/notifications/${params.notification_id}`);
                          toast.success("Notification updated successfully");
                        }else{
                          toast.error(`Error with updating notification! | ` + res.message ? res.message : "")
                        }
                      });
                    }}
                    label="Save"
                    className="ml-1 text-sm h-10"
                    icon={PencilIcon}
                    colour="warning"
                  />
                </span>
                
              </div>
            </div>
          )}
        </div>
      </Segment>
    </div>
  );
}
