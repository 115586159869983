import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import DebtSchedule from "../_components/DebtSchedule";
import DebtForm from "./DebtForm";
import Segment from "../../../../components/Segment";
import Add_Debt_Form from "./_forms/Add_Debt_Form";

import ChainedContext from "../../../../contexts/chainedContext";
import MatterReceiver from "../../../../contexts/matter_context/Matter_Receiver";
import DebtorReceiver from "../../../../contexts/debtor_context/Debtor_Receiver";
import API from "../../../../api";
import DebtorBar from "../_components/DebtorBar";
import toast from "react-hot-toast";
const BaseRate = [
  { date: dayjs("2018-08-03 00:00:00"), rate: 0.75 },
  { date: dayjs("2020-03-11 00:00:00"), rate: 0.25 },
  { date: dayjs("2020-03-19 00:00:00"), rate: 0.1 },
];
function AddDebt({ matter, debtor, history }) {
  const [debts, setDebts] = useState([]);
  const [debt, setDebt] = useState({});
  const [loading, setLoading] = useState(true);
  const [baseRate, setBaseRate] = useState({});
  const [contact, setContact] = useState({
    settings: {},
  });

  useEffect(() => {
    if (matter._id && matter.contact._id) {
      getDebt();
    }
  }, [matter]);

  useEffect(() => {
    if(loading)
      Promise.all([
        API.settings.base_rates.normal.get(),
        API.matters.debtors.getAll(matter._id)
      ])
      .then((data) => {
        if(data[0][0]){
          setBaseRate(data[0]);
        }

        API.matters.debtors.debt.getAll(matter._id, 0, data[1].debtors ? data[1].debtors.map(d => d._id) : []).then((r) => {
          setDebts(r);
          setLoading(false);
        });
      });
  }, [baseRate]);
  // const getContact = () => {
  //   API.contacts.get(matter.contact._id).then((res) => {
  //     setContact({
  //       _id: res._id,
  //       settings: res.settings,
  //     });
  //   });
  // };
  const getDebt = () => {
    API.matters.debtors.debt.getAll(matter._id, debtor._id).then((res) => {
      setDebts(res);
    });
  };
  const handleSubmit = (data) => {
    setDebts([...debts, data]);
  };
  const handleEdit = (data) => {
    if (data) {
      setDebt(data);
    } else {
      setDebt({});
    }
  };
  const handleData = (data) => {
    let formData = data;
    formData.matter = matter._id;
    formData.debtor = debtor._id;
    API.matters.debtors.debt
      .create(matter._id, debtor._id, formData)
      .then((res) => {
        getDebt();
        toast.success("Debt added");
        history.push(`/matters/${matter._id}/debtors/${debtor._id}/debt`);
      });
  };
  return (
    <div className="w-full  ">
      <Segment>
        <DebtorBar debtor={debtor} contact={matter.contact} />
        <Add_Debt_Form
          validateSubmit={(data) => {}}
          data={handleData}
          contact_settings={matter.contact.settings}
          matter_settings={{paymentTerms: matter.paymentTerms, interest_type: matter.invoice_interest_type ? matter.invoice_interest_type : (matter.contact.settings && matter.contact.settings.invoice_interest_type ? matter.contact.settings.invoice_interest_type : "")}}
          interest_rate={matter.interest_rate ? matter.interest_rate : (matter.contact.interest_rate ? matter.contact.interest_rate : (baseRate ? baseRate.rate : 0)) }
          matter_display_number={matter.display_number}
          debts={[]}
        />
        {/* <DebtForm BaseRate={BaseRate} submit={handleSubmit} update={debt} /> */}
      </Segment>
      <Segment>
        <DebtSchedule debts={debts} editData={handleEdit} history={history} />
      </Segment>
    </div>
  );
}
export default ChainedContext(AddDebt, [
  [MatterReceiver, "matter"],
  [DebtorReceiver, "debtor"],
]);
