import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Loader from "../../../../components/Loader";
import API from "../../../../api";
import CommonFunctions from "../../../../CommonFunctions";
import { Button, Input, SelectTwo, SelectZero, Toggle } from "../../../../components/Forms";
import {
  PencilIcon,
  PlusIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/solid";
import { ConfirmModal } from "../../../../components/Modals";
import Segment from "../../../../components/Segment";
import Select from "react-tailwindcss-select";

const initialState = {
  details: "",
  shortcode: "",
  time: 0,
  isBillable: true,
  defaultAdminRate: false,
  areaOfLaw: [],
};

export default function ChecklistManager({ type = "", header = "", org_id }) {
  const [areas, setAreas] = useState([]);

  const [listData, setListData] = useState([]);
  const [itemData, setItemData] = useState(initialState);

  const [modalOpen, setModalOpen] = useState("");
  const [localType, setLocalType] = useState(type);
  const [editing, setEditing] = useState(false);
  const [mount, setMount] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setMount(true);
  }, [localType]);

  useEffect(() => {
    if (mount === false && localType !== type) {
      setLocalType(type);
      setItemData(initialState);
      setEditing(false);
    }
  }, [mount, type, localType]);

  useEffect(() => {
    if (mount && type) {
      setMount(false);
      setLoading(true);

      API.settings
        .checklist(type)
        .getChecklistItems(false, org_id)
        .then((res) => setListData(res))
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setLoading(false));

      API.settings.doc_types().list(org_id).then((res) => 
        setAreas(res.filter(a => a.active && a._clioId && a.archived !== true).map(a => {
          return {
            label: a.name, value: a._id
          }
        }))
      )
    }
  }, [mount, loading, type]);

  const handleChange = (e) =>
    setItemData({ ...itemData, [e.target.name]: e.target.value });

  const handleNumberChange = (e) => {
    if (e.target.value >= 0) {
      setItemData({ ...itemData, [e.target.name]: e.target.value });
    }
  };

  const renderTable = (header, body) => (
    <div className={`text-lg`}>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {header.map((header_item, i) => {
                      return (
                        <th
                          key={"th_" + i}
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          {header_item.text}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {body.length > 0 ? (
                    body.map((body_item, body_index) => (
                      <tr key={body_index}>
                        {header.map((header_item, header_index) => (
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {body_item[header_item.value]}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <td
                      colspan={header.length}
                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center"
                    >
                      No Data...
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
  console.log("org", org_id);

  return (
    <>
      <ConfirmModal
        open={modalOpen !== ""}
        handleClose={() => setModalOpen("")}
        handleSubmit={() => {
          API.settings
            .checklist(type)
            .removeChecklist(modalOpen)
            .then(() => {
              setMount(true);
              setModalOpen("");
            })
            .catch((e) => CommonFunctions.handleError(e));
        }}
        header="Are you sure?"
        content={
          "Deleting is an irreversible action, but you can re-add an item with the same shortcode."
        }
      />
      <Segment>
        {header ? (
          <div>
            <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
              <span className="my-auto text-xl">{header}</span>
            </h3>
            <div className="w-full border-t border-gray-300 my-2" />
          </div>
        ) : (
          <></>
        )}
        <div className="pt-2 pb-4 md:grid md:grid-cols-12 md:gap-4">
          <Input
            label="Details"
            name="details"
            value={itemData.details}
            onChange={handleChange}
            className="col-span-3"
          />
          <Input
            label="Shortcode"
            name="shortcode"
            value={itemData.shortcode}
            onChange={handleChange}
            className="col-span-3"
          />
          <Input
            type="number"
            label="Time (Minutes)"
            name="time"
            incrementBy="6"
            value={itemData.time}
            onChange={handleNumberChange}
            className="col-span-3"
          />
          {header == "Matter" &&
          <div class="col-span-3">
            <label for="areaOfLaw" class="block text-sm font-medium text-gray-700">Area of Law</label>
            <Select 
              clearable
              isMultiple
              name="areaOfLaw"
              value={Array.isArray(itemData.areaOfLaw) ? itemData.areaOfLaw : []}
              onChange={(value) => setItemData({...itemData, areaOfLaw: value})}
              options={areas}
            />
          </div>}
          <div className="col-span-10 ml-auto">
            <Toggle
              className="pt-2 md:pt-0"
              label="Default Admin Rate"
              enabled={itemData.defaultAdminRate}
              onChange={() =>
                setItemData({
                  ...itemData,
                  defaultAdminRate: itemData.defaultAdminRate
                    ? !itemData.defaultAdminRate
                    : true,
                })
              }
            />
          </div>
          <div className="col-span-2 ml-auto">
            <Toggle
              className="pt-2 md:pt-0"
              label="Is Billable?"
              enabled={itemData.isBillable}
              onChange={() =>
                setItemData({
                  ...itemData,
                  isBillable: itemData.isBillable ? !itemData.isBillable : true,
                })
              }
            />
          </div>
          <div className="flex justify-between col-span-12">
            {editing ? (
              <Button
                label="Cancel"
                colour="alert"
                icon={XIcon}
                onClick={() => {
                  setEditing(false);
                  setItemData(initialState);
                }}
              />
            ) : (
              <></>
            )}
            <Button
              label={editing ? "Save" : "Add"}
              colour={editing ? "warning" : "positive"}
              icon={editing ? PencilIcon : PlusIcon}
              className={editing === false ? "ml-auto" : ""}
              onClick={() => {
                if (editing) {
                  API.settings
                    .checklist(type)
                    .updateChecklist(itemData._id, {
                      ...itemData,
                      order_btn: "",
                      action_btn: "",
                      time: itemData.time === "" ? 0 : itemData.time,
                      group: type,
                      org_id: org_id ? org_id : undefined,
                    })
                    .then(() => {
                      setEditing(false);
                      setItemData(initialState);
                    })
                    .catch((e) => CommonFunctions.handleError(e))
                    .finally(() => setMount(true));
                } else {
                  API.settings
                    .checklist(type)
                    .createChecklist({
                      ...itemData,
                      time: itemData.time === "" ? 0 : itemData.time,
                      group: type,
                      org_id: org_id ? org_id : undefined,
                    })
                    .then(() => {
                      setItemData(initialState);
                    })
                    .catch((e) => CommonFunctions.handleError(e))
                    .finally(() => setMount(true));
                }
              }}
              disabled={itemData.details && itemData.shortcode ? false : true}
            />
          </div>
        </div>
      </Segment>
      <Segment>
        {loading ? (
          <Loader />
        ) : (
          renderTable(
            header !== "Matter" ? [
              { text: "Details", value: "details" },
              { text: "Shortcode", value: "shortcode" },
              { text: "Time", value: "time" },
              { text: "Is Billable", value: "isBillable" },
              { text: "Default Rate", value: "defaultAdminRate" },
              { text: "Order", value: "order_btn" },
              { text: "Actions", value: "action_btn" },
            ] : [
              { text: "Details", value: "details" },
              { text: "Shortcode", value: "shortcode" },
              { text: "Time", value: "time" },
              { text: "Area of Law", value: "areaOfLaw" },
              { text: "Is Billable", value: "isBillable" },
              { text: "Default Rate", value: "defaultAdminRate" },
              { text: "Order", value: "order_btn" },
              { text: "Actions", value: "action_btn" },
            ],
            listData.map((item) => ({
              ...item,
              isBillable: item.isBillable === true ? "Yes" : "No",
              defaultAdminRate: item.defaultAdminRate === true ? "Yes" : "No",
              areaOfLaw: item.areaOfLaw.map((b) => {
                let area = areas.filter(a => a.value === b);
                return area[0] ? area[0].label : "Unknown";
              }).join(", "),
              order_btn: (
                <div className="mx-auto md:grid md:grid-cols-2 md:gap-x-0">
                  <div>
                    <Button
                      icon={ChevronUpIcon}
                      colour="grey"
                      className="md:ml-auto md:mr-2 mb-2 md:mb-0"
                      disabled={item.order === 1}
                      onClick={() => {
                        API.settings
                          .checklist(type)
                          .orderChecklist(item._id, { order: item.order - 1, org_id: org_id ? org_id : undefined })
                          .then(() => {
                            setMount(true);
                          })
                          .catch((e) => CommonFunctions.handleError(e));
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      icon={ChevronDownIcon}
                      colour="grey"
                      className="md:mr-auto md:mr-2"
                      disabled={item.order === listData.length}
                      onClick={() => {
                        API.settings
                          .checklist(type)
                          .orderChecklist(item._id, { order: item.order + 1, org_id: org_id ? org_id : undefined })
                          .then(() => {
                            setMount(true);
                          })
                          .catch((e) => CommonFunctions.handleError(e));
                      }}
                    />
                  </div>
                </div>
              ),
              action_btn: (
                <div className="mx-auto md:grid md:grid-cols-2 md:gap-x-0">
                  <Button
                    icon={PencilIcon}
                    colour="action"
                    className="md:ml-auto md:mr-2 mb-2 md:mb-0"
                    onClick={() => {
                      setEditing(true);
                      setItemData({ ...itemData, ...item, areaOfLaw: item.areaOfLaw.map(a => {
                        let match = areas.filter(b => b.value == a);
                        return {
                          label: match[0] ? match[0].label : "Unknown", value: a
                        }
                      }) });
                    }}
                  />
                  <Button
                    icon={TrashIcon}
                    colour="alert"
                    className="md:mr-auto md:mr-2"
                    onClick={() => {
                      setModalOpen(item._id);
                    }}
                  />
                </div>
              ),
            }))
          )
        )}
      </Segment>
    </>
  );
}

ChecklistManager.propTypes = {
  type: PropTypes.string.isRequired,
  header: PropTypes.string,
};
