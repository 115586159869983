import React, { useState, useEffect } from "react";
import CommonFunctions from "../../CommonFunctions";
import Header from "../../components/Header";
import API from "../../api";
import { CheckCircleIcon, CheckIcon, MailIcon, MailOpenIcon, OfficeBuildingIcon, PaperClipIcon, PencilIcon, PrinterIcon, UserIcon, XCircleIcon, XIcon } from "@heroicons/react/solid";
import { Button } from "../../components/Forms";
import { GiMagnifyingGlass } from "react-icons/gi";
import dayjs from "dayjs";
import _appConfig from "../../_appConfig";

export default function Home({ history }) {
  const [
    isContactSyncing,
    // setContactSyncing
  ] = useState(false);

  const [emails, setEmails] = useState([]);

  useEffect(() => {
    if (isContactSyncing === false) {
      Promise.all([
        API.postbox.getAll().catch((e) => CommonFunctions.handleError(e)),
      ]).then((res) => {
        setEmails(res[0].filter((email) => {
          //dayjs().diff(dayjs(e.completedAt), "hours") <= 48

          if(email.completed || email.status === "Cancelled"){
            if(dayjs().diff(dayjs(email.completedAt), "days") <= 2){
              return email;
            }
          }else{
            return email;
          }


        }));
      });
    }
  }, [isContactSyncing]);

  /*
  const emails = [
    { 
      _id: 1,
      title: 'LBA Task',
      client: "Eliot Gannon (egannon@liquid-computing.co.uk)",
      createdAt: "DD/MM/YYYY HH:mm:ss",
      completedAt: null,
      readAt: null,
      read: false,
      completed: false,
    },
    { 
      _id: 1,
      title: 'CCBC Claim Followup',
      client: "Tim Nolan (tnolan@liquid-computing.co.uk)",
      createdAt: "DD/MM/YYYY HH:mm:ss",
      completedAt: null,
      readAt: "DD/MM/YYYY HH:mm:ss",
      read: true,
      completed: false,
    },
    { 
      _id: 1,
      title: 'CCBC Claim Followup',
      client: "John Smith (jsmith@test123.com)",
      createdAt: "DD/MM/YYYY HH:mm:ss",
      completedAt: "DD/MM/YYYY HH:mm:ss",
      readAt: "DD/MM/YYYY HH:mm:ss",
      read: true,
      completed: true,
    },
  ]; */

  return (
    <div className="w-full h-full">
      <main className="flex-1 pb-8">
        {/* <div className="grid sm:grid-cols-3 gap-4 p-4"> */}
        {/* <div className="col-span-2"> */}
        <Header
          title={"Postbox"}
          body={
            <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
              <dd className="mt-3 -ml-0.5 flex items-center text-sm text-menuPrimaryText font-medium capitalize sm:mr-2 sm:ml-0 sm:mt-0">
                <MailIcon
                  className="flex-shrink-0 h-5 w-5 text-menuPrimaryText"
                  aria-hidden="true"
                />
                {emails.length > 0 ? emails.filter(e => e.read !== true).length : 0} Unread Email(s)
              </dd>
              <dd className="mt-3 -ml-0.5 flex items-center text-sm text-menuPrimaryText font-medium sm:ml-0 sm:mt-0">
                <MailOpenIcon
                  className="flex-shrink-0 h-5 w-5 text-menuPrimaryText"
                  aria-hidden="true"
                />
                {emails.length > 0 ? emails.filter(e => e.read === true && e.completed !== true && e.status !== "Cancelled").length : 0} Read & Unapproved Email(s)
              </dd>
              <dd className="mt-3 -ml-0.5 flex items-center text-sm text-menuPrimaryText font-medium sm:ml-0 sm:mt-0">
                <CheckIcon
                  className="flex-shrink-0 h-5 w-5 text-green-500"
                  aria-hidden="true"
                />
                {emails.length > 0 ? emails.filter(e => e.completed === true && e.status !== "Cancelled").length : 0} Approved Email(s)
              </dd>
              <dd className="mt-3 -ml-0.5 flex items-center text-sm text-menuPrimaryText font-medium sm:ml-0 sm:mt-0">
                <XIcon
                  className="flex-shrink-0 h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
                {emails.length > 0 ? emails.filter(e => e.status === "Cancelled").length : 0} Cancelled Email(s)
              </dd>
            </dl>
          }
        />

        

        <div className="px-4 sm:px-6 lg:px-8">
          <h2 className="mt-4 text-lg font-semibold">Postbox Requiring Action</h2>
          <div className="-mx-4 mt-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    &nbsp;
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Postbox Item
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Sending to
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Date Created
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">View</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {!emails.filter(e => e.completed !== true && e.status !== "Cancelled").length > 0 && <tr>
                  <td colSpan={6} className="px-3 py-4 text-sm text-gray-500 text-center">
                    Nothing in Postbox
                  </td>
                </tr>}
                {emails.filter(e => e.completed !== true && e.status !== "Cancelled").sort((a, b) =>
                    a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
                  ).map((item) => (
                  <tr key={item._id} onClick={() => history.push(`/postbox/${item._id}`)} className="hover:bg-gray-200 cursor-pointer">
                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                      {
                      item.postbox_type == "print" ? <PrinterIcon className="flex-shrink-0 h-5 w-5"
                      aria-hidden="true" /> :
                      item.read ?
                      <MailOpenIcon
                        className="flex-shrink-0 h-5 w-5 text-gray-300"
                        aria-hidden="true"
                      /> : 
                      <MailIcon 
                        className="flex-shrink-0 h-5 w-5"
                        aria-hidden="true"
                      />}
                      {item.attachment && <PaperClipIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />}
                      
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      {item.title}
                        <dl className="font-normal lg:hidden">
                          <dt className="sr-only">Person</dt>
                          <dd className="mt-1 truncate text-gray-700">{item.send}</dd>
                          <dt className="sr-only sm:hidden">Date</dt>
                          <dd className="mt-1 truncate text-gray-500 sm:hidden">{item.createdAt}</dd>
                        </dl>
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{item.send}</td>
                    <td className="px-3 py-4 text-sm text-gray-500">{item.createdAt}</td>
                    <td className="px-3 py-4 text-sm text-gray-500">{item.completed ? `Sent (${item.completedAt})` : item.read ? `Read (${item.readAt})` : "Unread"}</td>
                    <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      
                      {/*item.postbox_type == "print" ? 
                        <span className="isolate inline-flex rounded-md shadow-sm">
                          <button
                            type="button"
                            className={`relative inline-flex items-center ${item.attachment ? "rounded-l-md" : "rounded-md"} bg-blue-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-blue-400 focus:z-10`}
                            onClick={() => history.push(`/postbox/${item._id}`)}
                          >
                            <GiMagnifyingGlass className="flex-shrink-0 h-5 w-5" aria-hidden="true" />{" "}View
                          </button>
                          {item.attachment &&
                          <button
                            onClick={() => API.settings
                              .getFileLink(
                                  item.attachment
                              )
                              .then((res) => {
                                window.open(
                                  _appConfig.api_server + res,
                                  "_blank"
                                );
                              })}
                            type="button"
                            className="relative -ml-px inline-flex items-center rounded-r-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-blue-400 focus:z-10"
                          >
                           <PrinterIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />{" "}Document
                          </button>}
                        </span>
                      :
                      <span className="isolate inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          className="relative inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-blue-400 focus:z-10"
                          onClick={() => history.push(`/postbox/${item._id}`)}
                        >
                          <GiMagnifyingGlass className="flex-shrink-0 h-5 w-5" aria-hidden="true" />{" "}View
                        </button>
                      </span>
                      */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="px-4 sm:px-6 lg:px-8">
          <h2 className="mt-4 text-lg font-semibold">Recent Postbox Actioned</h2>
          <div className="-mx-4 mt-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    &nbsp;
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Postbox Item
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Sending to
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Date Created
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">View</span>
                  </th> */}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {!emails.filter(e => (e.completed === true || e.status === "Cancelled") && (dayjs().diff(dayjs(e.updatedAt), "day") < 3)).length > 0 && <tr>
                  <td colSpan={6} className="px-3 py-4 text-sm text-gray-500 text-center">
                    Nothing in Postbox
                  </td>
                </tr>}
                {emails.filter(e => (e.completed === true || e.status === "Cancelled") && (dayjs().diff(dayjs(e.updatedAt), "day") < 3)).sort((a, b) =>
                    a.updatedAt < b.updatedAt ? 1 : a.updatedAt > b.updatedAt ? -1 : 0
                  ).map((item) => (
                  <tr key={item._id} onClick={() => history.push(`/postbox/${item._id}`)} className="hover:bg-gray-200 cursor-pointer">
                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                      {item.status === "Cancelled" || item.status === "Unapproved" ? <XCircleIcon className="flex-shrink-0 h-5 w-5 text-red-500"
                        aria-hidden="true" /> : item.completed ? <CheckCircleIcon 
                        className="flex-shrink-0 h-5 w-5 text-green-500"
                        aria-hidden="true"
                      /> : item.read ?
                      <MailOpenIcon
                        className="flex-shrink-0 h-5 w-5 text-gray-300"
                        aria-hidden="true"
                      /> : 
                      <MailIcon 
                        className="flex-shrink-0 h-5 w-5"
                        aria-hidden="true"
                      />}
                      
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      {item.title}
                        <dl className="font-normal lg:hidden">
                          <dt className="sr-only">Person</dt>
                          <dd className="mt-1 truncate text-gray-700">{item.send}</dd>
                          <dt className="sr-only sm:hidden">Date</dt>
                          <dd className="mt-1 truncate text-gray-500 sm:hidden">{item.createdAt}</dd>
                        </dl>
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{item.send}</td>
                    <td className="px-3 py-4 text-sm text-gray-500">{item.createdAt}</td>
                    <td className="px-3 py-4 text-sm text-gray-500">{item.completed ? `${item.status} (${item.completedAt ? dayjs(item.complatedAt).format("DD/MM/YYYY HH:mm") : ""})` : item.read ? `Read (${item.readAt})` : "Unread"}</td>
                    {/* <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"> 
                      <Button
                        colour="action"
                        label="View"
                        onClick={() => history.push(`/postbox/${item._id}`)}
                        className="ml-4 text-sm"
                        icon={GiMagnifyingGlass}
                      />
                    </td>*/}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* </div> */}
      </main>
    </div>
  );
}
