import React, { useState, useEffect } from "react";
import Segment from "../../../components/Segment";
import ChainedContext from "../../../contexts/chainedContext";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import DebtorReceiver from "../../../contexts/debtor_context/Debtor_Receiver";
import API from "../../../api";
import MatterBar from "../_components/MatterBar";
import toast from "react-hot-toast";
import MatterBreadcrumbs from "../_components/MatterBreadcrumbs";
import CommonFunctions from "../../../CommonFunctions";
import Add_Fee_Form from "./Add_Fee_Form";
function AddFee({ matter, debtor, history }) {

  const [loading, setLoading] = useState(true);
  const [baseRate, setBaseRate] = useState({});

  const [matterDebtors, setMatterDebtors] = useState({});
  const [matterFees, setMatterFees] = useState([]);

  useEffect(() => {
    API.matters.debtors
      .getAll(matter._id)
      .then((res) => {
        setMatterDebtors(res);        
      })
        
      .catch((e) => CommonFunctions.handleError(e));
  }, [matter]);

  useEffect(() => {
    if(loading){

      Promise.all([
        API.settings.base_rates.normal.get(),
        API.matters.fees.getAll(matter._id)
      ]).then(data => {
        if(data[0][0]){
          setBaseRate(data[0]);
        }
        setMatterFees(data[1]);
        setLoading(false);
      })

    }
      
  }, [baseRate]);
  const handleData = (data) => {
    let formData = data;
    formData.matter = matter._id;
    formData.debtor = data.debtor;

    API.matters.fees
      .create(matter._id, formData)
      .then((res) => {

        if(res.success) {
          toast.success(res.message);
          history.push(`/matters/${matter._id}/fees`);
        }else{
          toast.error(res.message);
        }
        
      });
  };

  if(loading)
    return "Loading...";

  return (
    <div className="w-full  ">
      <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.type == "Company" ? `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}` : `${matter.contact.prefix} ${matter.contact.first_name} ${matter.contact.last_name} `} `}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number} ${matterDebtors.debtors ? `(${matterDebtors.debtors.map((d, i) => (i !== 0 ? " " : "") + d.debtor_name)})` : ""}`, href: `/matters/${matter._id}`, current: false },
        { name: "Fees", href: `/matters/${matter._id}/fees`,},
        { name: "Add", href: `#`, current: true },
      ]} />
      <Segment>
        <MatterBar matter={matter} history={history} />
        <Add_Fee_Form
          history={history}
          matter_id={matter._id}
          matter_display_number={matter.display_number}
          validateSubmit={(data) => {}}
          selectDebtor={true}
          matterFees={matterFees}
          data={handleData}
          contact_settings={matter.contact.settings}
          matter_settings={{paymentTerms: matter.paymentTerms, interest_type: matter.invoice_interest_type ? matter.invoice_interest_type : (matter.contact.settings && matter.contact.settings.invoice_interest_type ? matter.contact.settings.invoice_interest_type : "")}}
          interest_rate={matter.interest_rate ? matter.interest_rate : (matter.contact.interest_rate ? matter.contact.interest_rate : (baseRate ? baseRate.rate : 0)) }
        />
      </Segment>
    </div>
  );
}
export default ChainedContext(AddFee, [
  [MatterReceiver, "matter"],
  [DebtorReceiver, "debtor"],
]);
