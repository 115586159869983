import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

export default function SelectZero({
  label = "",
  name = "",
  value = "",
  options = [],
  onChange = () => {},
  onCreate = () => {},
  className = "",
  error = false,
  clearable = false,
  creatable = false,
}) {
  const customStyles = {
    option: (provided, state) => ({
      color: "black-500",
      padding: 10,
      background: state.isSelected
        ? `${state.isFocused ? "#d3d7de" : "#e2e4e9"}`
        : `${state.isFocused ? "#d3d7de" : "#F3F4F6"}`,
      cursor: "pointer",
    }),

    control: (control) => ({
      ...control,
      background: `${error ? "#FECACA" : "#F3F4F6"}`,
    }),

    menu: (menu) => ({
      ...menu,
      background: "#F3F4F6",
      padding: 0,
    }),

    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const color = "black-500";

      return { ...provided, opacity, transition, color };
    },

    input: (provided) => ({
      ...provided,
      color: "black-500",
    }),
  };

  options = options
    ? options.map((item) => ({ label: item.text, value: item.value }))
    : [];

  return (
    <div>
      <label
        htmlFor={name}
        className={`block text-sm font-medium ${
          error ? "text-red-500" : "black-500"
        }`}
      >
        {label}
      </label>
      {creatable ?
      <CreatableSelect
        name={name}
        autoFocus
        isSearchable
        isClearable={clearable}
        value={
          options && value
            ? options.find((item) => item.value === value)
              ? options.find((item) => item.value === value)
              : { label: "", value: "" }
            : { label: "", value: "" }
        }
        onChange={(e) =>
          onChange({ target: { name: name, value: e !== null ? e.value : "" } })
        }
        onCreateOption={(inputValue) => {
          let value =  inputValue.toLowerCase().replace(/\W/g, '');
          onCreate({
            label: inputValue,
            value,
          })
          options.push({
            label: inputValue,
            value,
          })
        }}
        options={options}
        className={`tailwindShadowFix mt-1 text-sm ${className}`}
        styles={customStyles}
      /> : <Select 
      name={name}
        autoFocus
        isSearchable
        isClearable={clearable}
        value={
          options && value
            ? options.find((item) => item.value === value)
              ? options.find((item) => item.value === value)
              : { label: "", value: "" }
            : { label: "", value: "" }
        }
        onChange={(e) =>
          onChange({ target: { name: name, value: e !== null ? e.value : "" } })
        }
        options={options}
        className={`tailwindShadowFix mt-1 text-sm ${className}`}
        styles={customStyles}
      
      />}
    </div>
  );
}

SelectZero.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  error: PropTypes.bool,
  clearable: PropTypes.bool,
};
