import React, { useState, useEffect } from "react";
import { Button } from "../../../components/Forms";
import API from "../../../api";
import Segment from "../../../components/Segment";
import CommonFunctions from "../../../CommonFunctions";
import Loader from "../../../components/Loader";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import ChainedContext from "../../../contexts/chainedContext";
import MatterBar from "../_components/MatterBar";
import MatterBreadcrumbs from "../_components/MatterBreadcrumbs";
import FeeSchedule from "../debtors/_components/FeeSchedule";
import toast, { LoaderIcon } from "react-hot-toast";
import dayjs from "dayjs";
function FeesList({ matter, history, match }) {
  const [fees, setFees] = useState([]);
  const [mountLoading, setMountLoading] = useState(true);
  const [syncingXero, setSyncingXero] = useState(false);
  const [orgXero, setOrgXero] = useState(false);

  const getData = () => {

    API.organisations.getMyOrg().then(o => setOrgXero(o._xeroTenantId ? true : false));

    API.matters.fees.getAll(matter._id).then((fees) => {
      setFees(fees);
    })
    .finally(() => setMountLoading(false))
    .catch((e) => CommonFunctions.handleError(e));
  }

  useEffect(() => {
      getData();      
  }, [matter]);

  const handleEdit = (data) => {
    alert("bang!");
  };

  return (
    <div className="">
      <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.type == "Company" ? `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}` : `${matter.contact.prefix} ${matter.contact.first_name} ${matter.contact.last_name} `} `}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number}`, href: `/matters/${matter._id}`, current: false },
        { name: "Fees", href: '#', current: true },
      ]} />
      <Segment>
        <MatterBar matter={matter} history={history} />
        {mountLoading ? (
          <Loader />
        ) : (
          <>           
            <div className="md:grid md:grid-cols-7 md:gap-30 pt-3 pb-4">
              <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                <div className="text-3xl">£{CommonFunctions.toFormattedFixed(fees.reduce((acc, fee) => acc + fee.amount, 0))}</div>
                <div className="text-xl text-primary">Total Amount Fees (inc. VAT)</div>
              </div>
              <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                <div className="text-3xl">£{CommonFunctions.toFormattedFixed(fees.reduce((acc, fee) => acc + (fee.amount * 0.2), 0))}</div> {/*grab from org settings */}
                <div className="text-xl text-primary">Total VAT</div>
              </div>
              <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                <div className="text-3xl">£{CommonFunctions.toFormattedFixed(fees.reduce((acc, fee) => acc + (parseFloat(fee.amount) - parseFloat(fee.amount * 0.2)), 0))}</div>
                <div className="text-xl text-primary">Total Amount Fees (ex. VAT)</div>
              </div>
              <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                <div className="text-3xl">£{CommonFunctions.toFormattedFixed(fees.reduce((acc, fee) => acc + (fee.amountPaid ? fee.amountPaid : 0), 0))}</div>
                <div className="text-xl text-primary">Total Fees Paid</div>
              </div>
              <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                {/* <div className="text-3xl">£{CommonFunctions.toFormattedFixed(fees.reduce((acc, fee) => acc + ((parseFloat(fee.amount) + parseFloat(fee.amount * 0.2)) - (fee.amountPaid ? fee.amountPaid : 0)), 0))}</div> */}
                <div className="text-3xl">£{CommonFunctions.toFormattedFixed(fees.reduce((acc, fee) => acc + ((parseFloat(fee.amount)) - (fee.amountPaid ? fee.amountPaid : 0)), 0))}</div>
                <div className="text-xl text-primary">Amount Left</div>
              </div>
              <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                <div className="text-3xl">{fees.length}</div>
                <div className="text-xl text-primary">Fees Charges</div>
              </div>
              <div className="text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                <Button
                  colour="positive"
                  label="Add Fee Charge"
                  onClick={() => history.push(`${match.url}/add`)}
                  className="mx-auto mb-2"
                  // disabled={!fees.length > 0}
                />{}
                <Button
                  colour="action"
                  label="Add Payment"
                  onClick={() => history.push(`${match.url}/payment`)}
                  className="mx-auto mb-2"
                  disabled={!fees.length > 0}
                />
                <Button
                  label={!orgXero ? "Xero not linked!" : (syncingXero ? <>Sending to Xero{" "}<LoaderIcon className="h-5 w-5" /></> : "Sync Xero")}
                  disabled={syncingXero || !orgXero}
                  colour="warning"
                  onClick={() => { 
                    
                    setSyncingXero(true);

                    API.matters.syncXero(matter._id).then(res => {
                      setSyncingXero(false);
                      if(res.success){
                        toast.success(res.message);
                        getData(); 
                      }else{
                        toast.error(res.message);
                      }
                    } ).catch(err => { 
                      setSyncingXero(false);
                      console.log("🚀 ~ API.matters.syncXero ~ err:", err)
                      toast.error("Server error") 
                    })
                
                }
                
                }
                  className="mx-auto mb-2"
                />
                <Button
                  label={!orgXero ? "Xero not linked!" : (syncingXero ? <>Sending to Xero{" "}<LoaderIcon className="h-5 w-5" /></> : "Send to Xero")}
                  disabled={syncingXero || !orgXero || matter.xero_send === "instant" } // || !fees.filter(f => f.link == true).length > 0
                  colour="warning"
                  onClick={() => {                     
                    // setSyncingXero(true);

                    API.matters.syncLinkXero(matter._id, fees.filter(f => f.link == true)).then(res => {
                      
                      setSyncingXero(false);
                      if(res.success){
                        toast.success(res.message);
                        getData(); 
                      }else{
                        toast.error(res.message);
                      }
                    } ).catch(err => { 
                      setSyncingXero(false);
                      console.log("🚀 ~ API.matters.syncXero ~ err:", err)
                      toast.error("Server error") 
                    })
                
                }
                
                }
                  className="mx-auto mb-2"
                />
                {/* {matter.xero_send === "instant" && <i>Matter Xero settings is set to instant so Invoices are created on Xero soon as created unable to link before send!</i>} */}
                <p>Last Synced: {matter.lastSync ? dayjs(matter.lastSync).toString() : "Not Synced with Xero!"}</p>
              </div>
            </div>
            <FeeSchedule
              debts={fees}
              matter={matter}
              editData={handleEdit}
              onLink={(inx) => {
                let temp = fees;

                if(temp[inx].link == undefined) { 
                  temp[inx].link = false;
                }

                temp[inx].link = !temp[inx].link;
                setFees(temp);
              }}
              match={match}
              history={history}
            />
          </>
        )}
      </Segment>
    </div>
  );
}
export default ChainedContext(FeesList, [
  [
    MatterReceiver,
    (matter) => {
      return { matter };
    },
  ],
]);
