import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../../api";
import CommonFunctions from "../../../CommonFunctions";
import { PencilIcon, PlusIcon } from "@heroicons/react/solid";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Segment from "../../../components/Segment";
import { Switch } from "@headlessui/react";

export default function Add({ history }) {
  // const [listData, setListData] = useState([]);

  const params = useParams();

  const [data, setData] = useState({
    name: "",
    subject: "",
    body_data: "",
    footer_data: "",
  });
  // const [itemData, setItemData] = useState(initialState);

  const [editing, setEditing] = useState(false);
  const [mount, setMount] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mount && params.id) {
      setMount(false);
      setLoading(true);
      setEditing(true);

      API.settings
        .activity_categories()
        .get(params.id)
        .then((res) =>
          setData({
            name: res.name,
            icon: res.icon,
            menu_show: res.menu_show ? res.menu_show : false,
            org_id: params.org_id ? params.org_id : ""
          })
        )
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setLoading(false));
    }
  }, [mount, loading]);

  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  return (
    <Segment>
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {editing ? "Save" : "Add"} Activity
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {editing ? "Save" : "Add"} Activity details
            </p>
          </div>

          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Name
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={data.name}
                  onChange={handleChange}
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:max-w-xs sm:text-sm"
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Show on Menu
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Switch
                checked={data.menu_show}
                onChange={() => setData({ ...data, menu_show: !data.menu_show })}
                className={`${data.menu_show ? 'bg-primary' : 'bg-gray-200'} relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2`
                }
              >
                <span className="sr-only">Show on Menu</span>
                <span
                  className={`${data.menu_show ? 'translate-x-5' : 'translate-x-0'} pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`
                  }
                >
                  <span
                    className={`${data.menu_show ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in'} absolute inset-0 flex h-full w-full items-center justify-center transition-opacity`
                    }
                    aria-hidden="true"
                  >
                    <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path
                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span
                    className={`${data.menu_show ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out'} absolute inset-0 flex h-full w-full items-center justify-center transition-opacity`}
                    aria-hidden="true"
                  >
                    <svg className="h-3 w-3 text-black" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="icon"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                SVG Icon (JSX)
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <textarea
                  id="icon"
                  name="icon"
                  rows={10}
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                  defaultValue={""}
                  value={data.icon}
                  onChange={handleChange}
                />
                <p className="mt-2 text-sm text-gray-500">
                  JSX SVG icon, icons used by{" "}
                  <a
                    className="hover:text-orange-500 underline"
                    href="https://heroicons.com/"
                    target="_blank"
                  >
                    Heroicons
                  </a>
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700">
                Icon Preview
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <div className="flex items-center">
                  <div
                    className="h-10 w-10"
                    dangerouslySetInnerHTML={{ __html: data.icon }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            onClick={() => history.push(params.org_id ? 
              `/admin/organisations/${params.org_id}/activity_categories/` : "/settings/activity_categories")}
            type="button"
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={!data.name}
            onClick={() => {
              let sendData = {
                name: data.name,
                icon: data.icon,
                menu_show: data.menu_show,
                org_id: params.org_id ? params.org_id : ""
              };

              if (editing) {
                API.settings
                  .activity_categories()
                  .edit(params.id, sendData)
                  .then((res) => history.push(params.org_id ? 
                    `/admin/organisations/${params.org_id}/activity_categories/` :"/settings/activity_categories"))
                  .catch((e) => CommonFunctions.handleError(e));
              } else {
                API.settings
                  .activity_categories()
                  .add(sendData)
                  .then((res) => history.push(params.org_id ? 
                    `/admin/organisations/${params.org_id}/activity_categories/` :"/settings/activity_categories"))
                  .catch((e) => CommonFunctions.handleError(e));
              }
            }}
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {/* {editing ? "orange" : "green"}  */}
            {editing ? PencilIcon : PlusIcon}
            {editing ? "Save" : "Add"}
          </button>
        </div>
      </div>
    </Segment>
  );
}
