import React, { useEffect, useState, Fragment } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { CheckIcon, XIcon, BookOpenIcon, TrashIcon, DocumentTextIcon, SearchIcon } from "@heroicons/react/solid";
import { Dialog, Switch, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import ChainedContext from "../../../../contexts/chainedContext";
import MatterReceiver from "../../../../contexts/matter_context/Matter_Receiver";
import {
  Button,
  Group,
  Input,
  SelectTwo,
  SelectZero,
  TextArea,
} from "../../../../components/Forms";
import API from "../../../../api";
import Add_Debt_Form from "../debt/_forms/Add_Debt_Form";
import DebtSchedule from "../_components/DebtSchedule";
import DocumentsUploader from "../../_components/DocumentsUploader";
import { CountiesList} from "../../../../_lists";

const company_types = [
  { text: "Sole Trader", value: "Sole Trader" },
  { text: "Individual", value: "Individual" },
  { text: "PLC", value: "PLC" },
  { text: "Partnership", value: "Partnership" },
  { text: "LTD", value: "LTD" },
  { text: "LLP", value: "LLP" },
  { text: "Charity", value: "Charity" },
  { text: "Other", value: "Other" },
];

const DebtorsForm = ({ contact, matter, debtor = {}, onSubmit, onCancel, history, addDebtor, add = false }) => {
  const [salutations, setSalutations] = useState([]);
  const [data, setData] = useState({
    debtor_name: "",
    debtor_salutation: "",
    debtor_firstName: "",
    debtor_surname: "",
    company_type: "",
    registered_number: "",

    street: "",
    address2: "",
    city: "",
    // county: "Kent",
    county: "",
    postal_code: "",
    country: "United Kingdom",

    contact_title: "",
    contact_firstname: "",
    contact_surname: "",
    contact_name: "",
    // first_name: "",
    // last_name: "",
    contact_email: "",
    contact_phone: "",
    letter_salutation: "",

    amount_owed: 0,
    currency: "GBP",
    description: "",
    other_information: "",
    terms_of_business: false,
    drafting_contact: false,
    terms_and_conditions: false,

    validDebt: true,
    validDebtor: true,
    creatingDebtor: false
  });

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [creditReports, setCreditReports] = useState([]);
  const [activeReport, setActiveReport] = useState({});
  const [debts, setDebts] = useState([]);
  const [baseRate, setBaseRate] = useState({});
  const [loadingBaseRate, setLoadingBaseRate] = useState(true);
  const [loadingSalutaions, setLoadingSalutaions] = useState(true);
  const [isUploading_invoices, setIsUploading_invoices] = useState(true);
  const [isUploading_files, setIsUploading_files] = useState(true);

  const [creditLoading, setCreditLoading] = useState(false);

  const [creditSearchIsOpen, setCreditSearchIsOpen] = useState(false);
  const [creditSearchResult, setCreditSearchResult] = useState({
      totalSize: 0,
      companies: [],
    });

  const getDebt = () => {
    API.matters.debtors.debt.getAll(matter._id, debtor._id).then((res) => {
      setDebts(res);
    });
  };

  useEffect(() => {
    if (modalOpen === true) {
      setCreditLoading(true);
      API.external_providers.credit_safe
        .searchCompanies({
          countries: "GB",
          name: data.debtor_name,
        })
        .then((res) => {
          setCreditReports(res.companies ? res.companies : []);
          setCreditLoading(false);
        });
    }
  }, [modalOpen]);

  useEffect(() => {

    if(loadingBaseRate){
        API.settings.base_rates.normal.get().then((data) => {
          if(data[0]){
            setBaseRate(data[0]);
          }
          setLoadingBaseRate(false);
      });
    }
    
  }, [baseRate]);

  useEffect(() => {
    if (debtor._id !== undefined) {
      setData(debtor);
    }
  }, [debtor]);

  useEffect(() => {
    if(contact && contact.settings){
      setData({
        ...data,
        letter_salutation: contact.settings.letterSalutation ? contact.settings.letterSalutation : "",
      })
    }
    
  }, [contact]);

  useEffect(() => {

    if(loadingSalutaions){
      API.settings.salutations.get().then(res => {
        setSalutations(res.map(s => {
          return {
            text: s.title,
            value: s.title
          }
        }))
        setLoadingSalutaions(false);
      })
    }
  }, [salutations]);

  const handleSubmit = () => {
    // TODO Validation
    if(isUploading_invoices){
      // toast.error("Unable to continue until all invoices uploads have completed");
      // return;
    }
    if(isUploading_files){
      toast.error("Unable to continue until all file(s) uploads have completed");
      return;
    }

    setData({
      ...data,
      creatingDebtor: true
    })

    const newData = {
      ...data
    }
    onSubmit(addDebtor ? {data: newData, debts} : newData);
  };
  const onChange = (e) => {
    
    if(e.target.name === "contact_firstname" || e.target.name === "contact_surname"){

      let f = e.target.name === "contact_firstname" ? e.target.value : (data.contact_firstname ? data.contact_firstname : "");
      let l = e.target.name === "contact_surname" ? e.target.value : (data.contact_surname ? data.contact_surname : "");

      setData({ ...data, [e.target.name]: e.target.value, contact_name: f + " " + l });
    }else if(e.target.name === "debtor_firstName" || e.target.name === "debtor_surname"){

      let f = e.target.name === "debtor_firstName" ? e.target.value : (data.debtor_firstName ? data.debtor_firstName : "");
      let l = e.target.name === "debtor_surname" ? e.target.value : (data.debtor_surname ? data.debtor_surname : "");

      setData({ ...data, [e.target.name]: e.target.value, debtor_name: f + " " + l });
    }else{
      setData({ ...data, [e.target.name]: e.target.value });
    }

  };

  const handleStartCreditCheck = () => {
    API.external_providers.credit_safe
      .searchCompanies({
        countries: "GB",
        name: data.debtor_name,
      })
      .then((res) => {
        setCreditSearchIsOpen(true);
        setCreditSearchResult(res);
      });
  };

  const companySearchDialog = () => {
    return (
      <Transition
        show={creditSearchIsOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={creditSearchIsOpen}
          onClose={() => setCreditSearchIsOpen(false)}
          className="fixed z-10 inset-0 overflow-y-auto "
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className=" inline-block w-full max-w-7xl p-4 my-4 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="pt-2l">
                <Dialog.Title
                  as="h3"
                  className="text-xl leading-6 font-medium text-gray-900 border-b border-gray-400 py-2"
                >
                  Companies
                </Dialog.Title>
              </div>
              <div>{renderCreditCheckSearchResults()}</div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const handleGetCreditReport = (type, resultItem) => () => {

    API.matters.debtors.credit_checks.newReport(matter._id, debtor._id, type,resultItem.id)
      .then(() => {
        toast.success("Credit Report ran and linked to debtor");
        setCreditSearchIsOpen(false);
        setCreditSearchResult({
          totalSize: 0,
          companies: [],
        });
      });
  };

  const renderCreditCheckSearchResults = () => {
    if (creditSearchResult.companies.length) {
      return (
        <div>
          <h2>
            Company Search Results for "{data.debtor_name}"
          </h2>

          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-800">
            <thead>
              <tr className="bg-gray-50 dark:bg-gray-900">
                <th>ID</th>
                <th>Name</th>
                <th>Status</th>
                <th>Reg Num</th>
                <th>Vat Num</th>
                <th>Address</th>
                <th>{""}</th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-black divide-y divide-gray-200">
              {creditSearchResult.companies.map((item) => {
                return (
                  <tr key={item.id} className="bg-white dark:bg-black">
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.status}</td>
                    <td>{item.regNo}</td>
                    <td>
                      {item.vatNo !== undefined ? item.vatNo.join(", ") : ""}
                    </td>
                    <td>{item.address.simpleValue}</td>
                    <td>
                      <Button
                        colour="warning"
                        label="Get JSON"
                        onClick={handleGetCreditReport("json", item)}
                        icon={DocumentTextIcon}
                      />
                      {/*<Button*/}
                      {/*    colour="blue"*/}
                      {/*    label="Get PDF"*/}
                      {/*    onClick={handleGetCreditReport("pdf", item)}*/}
                      {/*    icon={DocumentIcon}*/}
                      {/*/>*/}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  };

  const renderModal = () => {
    const handleClose = () => {
      setModalOpen(false);
    };

    function toTitleCase(str) {
      return str.toLowerCase().split(' ').map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ');
    }

    const handleModalSubmit = () => {

      let street = activeReport.address
      ? activeReport.address.simpleValue
      : ""

      let foundCounty = null;

      const countyValues = CountiesList.map(county => county.value.toLowerCase());
      const words = street.replace(/,/g, '').split(' ');

      words.forEach(word => {
        const lowercaseWord = word.toLowerCase();
        const index = countyValues.indexOf(lowercaseWord);
        if (index !== -1) {
          foundCounty = CountiesList[index].value;
        }
      });

      /*
      let street = activeReport.address
      ? activeReport.address.street
        ? activeReport.address.street
        : activeReport.address.simpleValue
      : "" */

      // Split the address into parts
      const parts = street.split(", ");

      const formattedStreet = parts
      .filter(part => part !== activeReport.address.postCode && part !== activeReport.address.city)
      .map((part) => {
          return part.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
      }); 

      setData({
        ...data,
        debtor_salutation: activeReport.salutation ? activeReport.salutation : "",
        debtor_firstName: activeReport.firstName ? activeReport.firstName : "",
        debtor_surname: activeReport.surname ? activeReport.surname : "",
        // debtor_name: activeReport.name ? activeReport.name : "",
        debtor_name: activeReport.name ? toTitleCase(activeReport.name) : "",
        companyRegistrationNumber: activeReport.regNo,
        vatRegistrationNumber: activeReport.vatNo ? activeReport.vatNo[0] : "",
        city: activeReport.address && activeReport.address.city ? activeReport.address.city.charAt(0).toUpperCase() + activeReport.address.city.slice(1).toLowerCase() : "",
        // country: "United Kingdom",
        country: activeReport.country,
        street: formattedStreet[0],
        county: foundCounty? foundCounty : "",
        address2: formattedStreet.length > 1 ? formattedStreet.slice(1).join(", ") : "",
        postcode: activeReport.address ? activeReport.address.postCode : "",
      });
      toast.success("Data imported successfully");
      setModalOpen(false);
      if(!add){
        handleStartCreditCheck();
      }
    };

    return (
      <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={handleClose}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:py-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Credit Lookup For {data.debtor_name} 
                      {/* ({data.companyRegistrationNumber}) */}
                    </Dialog.Title>
                    <div className="mt-4">
                      <div className={`overflow-x-auto text-lg`}>
                        <table
                          className={`min-w-full divide-y divide-gray-300 border border-gray-300 table-fixed`}
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                              >
                                Reg Number
                              </th>
                              <th
                                scope="col"
                                className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                              >
                                Vat Number
                              </th>
                              <th
                                scope="col"
                                className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                              >
                                Address
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-300">
                            {creditLoading ? <tr>
                              <td colSpan={"5"} className="text-center">
                              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-black inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>

                              Loading...
                              </td>
                            </tr> : creditReports.length > 0 ? (
                              creditReports.map((item, index) => (
                                <tr
                                  className={`hover:bg-gray-100 focus:bg-gray-100 cursor-pointer ${
                                    activeReport.id === item.id
                                      ? "bg-gray-300 hover:bg-gray-400 focus:bg-gray-400"
                                      : ""
                                  }`}
                                  onClick={() => setActiveReport(item)}
                                  key={index}
                                >
                                  <td className="px-4 py-4">{item.name}</td>
                                  <td className="px-4 py-4">{item.status}</td>
                                  <td className="px-4 py-4">{item.regNo}</td>
                                  <td className="px-4 py-4">{item.vatNo}</td>
                                  <td className="px-4 py-4">
                                    {item.address && item.address.simpleValue}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className={`text-black `}>
                                <td colSpan={5} className="px-4 py-4">
                                  No Credit Reports...
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <Button
                    label="Import Data"
                    colour="warning"
                    onClick={handleModalSubmit}
                  />
                  <Button
                    label="Cancel"
                    colour="alert"
                    onClick={handleClose}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const handleData = (d) => {
    let formData = d;
    formData.matter = matter._id;
    if(addDebtor){

      let d = debts;
      d.push(formData);

      setDebts(d);
      setData({ ...data});

      /*

      if(debts.length > 0){
        let update = debts.push(formData);
        setDebts([update]);
      }else{
        setDebts([formData]);
      } */

      let credit = formData.credit ? formData.credit : 0;
      let amount = formData.amount;
      let interest = 0;
      let compensation = formData.compensation;
      const getInterest = (value, interest) => {
        let fig1 = value / 100;
        let fig2 = interest / 365;
        return fig1 * fig2;
      };

      if (amount) {
        let days = dayjs().diff(dayjs(formData.interestDate), "day");
        interest = interest + getInterest(amount, formData.interest) * days;
      }

      data.amount_owed = (amount + interest + compensation) - credit;
    }else{
      formData.debtor = debtor._id;
      API.matters.debtors.debt
        .create(matter._id, debtor._id, formData)
        .then((res) => {
          getDebt();
          toast.success("Debt added");
        });
    }
  };

  let classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  const confirmDeleteModal = () => {

    const handleClose = () => {
      setConfirmDeleteOpen(null);
    };

    return <Transition.Root show={confirmDeleteOpen !== null} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={handleClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    Delete File
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want remove this file? It will be removed from the system.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <Button
                  label="Delete"
                  colour="alert"
                  onClick={() => {

                    let remove = [
                      ...debtor.files.filter((f) => f.file && f.file._id == confirmDeleteOpen),
                      ...debtor.invoices.filter((f) => f.file && f.file._id == confirmDeleteOpen)]

                    if(remove.length > 0){
                      API.clio.documents.removeFile(remove[0].clioId).then(() => {
                        toast.success("File Removed successfully from Clio!");

                        API.matters.debtors.removeFile(matter._id, debtor._id, confirmDeleteOpen).then(res => {
                          toast.success("File Removed successfully from Bridge!");
                          debtor._refresh();
                          handleClose();
                        }).catch(err => {
                          toast.error(err.response.data.message);
                        }) 

                      })
                    }

                  }}
                />
                <Button
                  label="Cancel"
                  colour="alert"
                  onClick={() => handleClose()}
                />
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
  }

  return (
    <React.Fragment>
      {renderModal()}
      {confirmDeleteModal()}
      {companySearchDialog()}
      <Group className="my-2">
        <div>
          <label for="type" class="block text-sm font-medium text-gray-700">Corporate / Individual</label>
          <Switch.Group
            as="div"
            className="flex items-center pt-2"
          >
            <Switch
              checked={data.company_type === "Individual"}
              onChange={() =>  setData({ ...data, debtor_firstName: "", debtor_surname: "", company_type: data.company_type === "Individual" ? "" : "Individual" })}
              className={classNames(
                data.company_type === "Individual" ? "bg-primary" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  data.company_type === "Individual"
                    ? "translate-x-5"
                    : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3">
              <span className="text-sm font-medium text-gray-900">
                {data.company_type === "Individual" ? "Individual" : "Corporate"}
              </span>
            </Switch.Label>
          </Switch.Group>
        </div>

        {data.company_type == "Individual" && <>

        <SelectZero
            label="Title"
            name="debtor_salutation"
            value={data.debtor_salutation}
            options={salutations}
            onChange={onChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        <Input
          label="First Name"
          name="debtor_firstName"
          value={data.debtor_firstName}
          
          onChange={onChange}
        />
        <Input
          label="Surname"
          name="debtor_surname"
          value={data.debtor_surname}
          
          onChange={onChange}
        /> </>}
        
        <Input
          disabled={data.company_type == "Individual"}
          label="Debtor Name"
          name="debtor_name"
          value={data.debtor_name}
          
          onChange={onChange}
        />
        {/*  
        <SelectTwo
          label="Company Type"
          placeholder="Please Select Type"
          field="company_type"
          value={data.company_type ? data.company_type : "Other"}
          options={company_types}
          onChange={onChange}
        />*/}
        {data.company_type !== "Individual" && (
          <Input
            label="Registered Number"
            name="companyRegistrationNumber"
            value={data.companyRegistrationNumber}
            onChange={onChange}
          />
        )}
        {data.company_type !== "Individual" && (
          <div className="md:col-span-1">
            <label for="creditlookup" class="block text-sm font-medium text-gray-700">&nbsp;</label>
            <Button
              colour="warning"
              label="Credit Lookup"
              onClick={() => {
                setModalOpen(true);
              }}
              className="mx-auto md:creditLookupButton"
              icon={BookOpenIcon}
              disabled={
                // data.debtor_name && data.companyRegistrationNumber
                data.debtor_name
                  ? false
                  : true
              }
            />
          </div>
        )}
      </Group>
      <div className="p-4 border border-primary rounded-md">
        <div className="text-lg text-primary">Debtor Details</div>
        <div className="grid grid-cols-2 gap-4 py-2">
          <div className="grid col-span-1 gap-4 py-2">
            {/*  
            <div className="flex gap-1">
              <Input
                className="w-full"
                type="number"
                label="Amount Owed (£)"
                name="amount_owed"
                value={data.amount_owed}
                onChange={onChange}
                disabled={addDebtor}
              />
              <Input
                type="text"
                label="Currency"
                name="currency"
                value={data.currency}
                onChange={onChange}
              />
            </div>*/}
            <TextArea
              label="Notes"
              name="description"
              value={data.description ? data.description : ""}
              onChange={onChange}
            />
            {/*  
            <TextArea
              label="Other Information"
              name="other_information"
              value={data.other_information ? data.other_information : ""}
              onChange={onChange}
            />*/}
          </div>
          <div className="flex flex-col gap-4 py-2">
            {/* 
            <div>
              <DocumentsUploader title={"Individual Invoice Details"} existingDocuments={data?.invoices} isUploadingInProgress={a => setIsUploading_invoices(a)} onUploadedDocument={documents => {
                // setInvoices(documents);
                 setData(a => {
                   return {
                     ...a,
                     invoices: documents
                   }
                 });
              }} />
            </div> */}
            <div>
              <DocumentsUploader title={"Upload Files"} existingDocuments={data?.files} isUploadingInProgress={a => setIsUploading_files(a)} onUploadedDocument={documents => {
                // setFiles(documents);
                 setData(a => {
                   return {
                     ...a,
                     files: documents
                   }
                 });
              }} />
            </div>
          </div>
        </div>
      </div>
      {addDebtor &&
      <div className="p-4 border border-primary rounded-md mt-2">
        <div className="text-lg text-primary">Debts</div>
        <div className="gap-4 py-2">
          <Add_Debt_Form
            data={handleData}
            // validateSubmit={(data) => setData({ ...data, validDebt: data.notcomplete ? false : true })}
            validateSubmit={() => {}}
            contact_settings={matter.contact.settings}
            matter_settings={{paymentTerms: matter.paymentTerms, interest_type: matter.invoice_interest_type ? matter.invoice_interest_type : (matter.contact.settings && matter.contact.settings.invoice_interest_type ? matter.contact.settings.invoice_interest_type : "")}}
            interest_rate={matter.interest_rate ? matter.interest_rate : (matter.contact.interest_rate ? matter.contact.interest_rate : (baseRate ? baseRate.rate : 0)) }
          />
          {/* <DebtForm BaseRate={BaseRate} submit={handleSubmit} update={debt} /> */}
          <DebtSchedule debts={debts} history={history} />
        </div>
      </div>}
      <div className="grid grid-cols-2 gap-4 py-2">
        <div className="p-4 border border-primary rounded-md">
          <div className="text-lg text-primary">Address</div>

          <div className="flex flex-col gap-2 py-2">
            <Input
              label="Street"
              name="street"
              value={data.street}
              onChange={onChange}
            />
            <Input
              label="Address 2"
              name="address2"
              value={data.address2}
              onChange={onChange}
            />
          </div>
          <Group className="py-2">
            <Input
              label="Town/City"
              name="city"
              value={data.city}
              onChange={onChange}
            />
            <Input
              label="Postcode"
              name="postcode"
              value={data.postcode}
              onChange={onChange}
            />
          </Group>
          <Group className="py-2">
            <div className="">
              <label
                htmlFor={"country"}
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Country
              </label>
              <CountryDropdown
                name="country"
                value={data.country ? data.country : ""}
                defaultOptionLabel="GB"
                // whitelist={["GB", "IE"]}
                onChange={(val) =>
                  setData({
                    ...data,
                    country: val,
                  })
                }
                classes="p-2 shadow-sm block w-full sm:text-sm rounded-md bg-gray-200"
                valueType="short"
              />
            </div>
            {(data.country === "United Kingdom" || data.country === "GB") &&
            <div className="">
              {/*  
              <label
                htmlFor={"province"}
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                County
              </label>

              <RegionDropdown
                country={data.country}
                value={data.county ? data.county : ""}
                onChange={(val) =>
                  setData({
                    ...data,
                    county: val,
                  })
                }
                countryValueType="short"
                classes="p-2 shadow-sm block w-full sm:text-sm rounded-md bg-gray-200"
              />

              */}


              <SelectTwo
                label="County"
                placeholder="Please Select Type"
                field="county"
                value={data.county}
                options={CountiesList}
                onChange={onChange}
                clearable={true}
              />
              
            </div>}
          </Group>
        </div>

        <div className="p-4 border border-primary rounded-md">
          <div className="text-lg text-primary">Contact</div>

          {data.company_type !== "Individual" && (
          <div className="flex gap-4 py-2">
            {/*  
            <Input
              label="Title"
              name="contact_title"
              value={data.contact_title}
              onChange={onChange}
              className="w-1/3"
            />*/}
            
            <div className="w-1/3">
              <SelectZero
                label="Title"
                name="contact_title"
                value={data.contact_title}
                options={salutations}
                onChange={onChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>

            <Input
              label="First Name"
              name="contact_firstname"
              value={data.contact_firstname}
              onChange={onChange}
              className="w-1/3"
            />
            <Input
              label="Surname"
              name="contact_surname"
              value={data.contact_surname}
              onChange={onChange}
              className="w-1/3"
            />
            <Input
              disabled
              label="Name"
              name="contact_name"
              value={data.contact_name}
              onChange={onChange}
              className="w-full"
            />
            {/* <Input
                         label="Last Name"
                         name="last_name"
                         value={data.contact.last_name}
                         onChange={onContactChange}
                         className="w-full"
                         /> */}
          </div>)}
          <div className="flex flex-col gap-4 py-2">
            <Input
              label="Email"
              name="contact_email"
              value={data.contact_email}
              onChange={onChange}
            />
            <Input
              label={"Phone Number"}
              name={"contact_phone"}
              value={data.contact_phone}
              onChange={onChange}
            />
            <Input
              label={"Letter Salutation"}
              name={"letter_salutation"}
              value={data.letter_salutation}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      {/* <h2>Other</h2>
             <Group className="py-2">
             <Toggle
             label="Accepted Terms Of Business"
             enabled={data.terms_of_business}
             onChange={() => {
             setData({ ...data, terms_of_business: !data.terms_of_business });
             }}
             />
             <Toggle
             label="Allowed Contact For Drafting"
             enabled={data.drafting_contact}
             onChange={() => {
             setData({ ...data, drafting_contact: !data.drafting_contact });
             }}
             />
             <Toggle
             label="Terms And Conditions"
             enabled={data.terms_and_conditions}
             onChange={() => {
             setData({
             ...data,
             terms_and_conditions: !data.terms_and_conditions,
             });
             }}
             />
             </Group> */}
      <div className="pt-2 flex justify-between">
        <Button colour="alert" label="Cancel" onClick={onCancel} icon={XIcon} />
             {data.creatingDebtor ? 
             <div className="text-white font-bold py-2 px-4 rounded">
                <svg aria-hidden="true" style={{margin: "0 auto"}} class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                  </svg>
              </div> : 
              <Button colour={debtor._id !== undefined  ? "warning" : "positive"} label={debtor._id !== undefined ? "Update" : "Create Debtor"}
                onClick={handleSubmit}
                icon={CheckIcon}
                style={{textAlign: 'center'}}
              />
        }        
      </div>
    </React.Fragment>
  );
};
export default ChainedContext(DebtorsForm, [
  [MatterReceiver, "matter"]
]);