import { BasicTable } from "../../components/Tables";
import dayjs from "dayjs";
import { LicenseInfo } from '@mui/x-license-pro';
import { DataGridPro, GridRowsProp, GridColDef} from '@mui/x-data-grid-pro';
import { GiMagnifyingGlass } from "react-icons/gi";
import { Button } from "../../components/Forms";

// LicenseInfo.setLicenseKey('12782d1db78016f47ef9484b92483467Tz03MzE4MCxFPTE3MjQzMjM4NzYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='); // Expired Aug 2024
LicenseInfo.setLicenseKey('d901618fad761ed0ffd81b8b4a86382eTz05ODYxOCxFPTE3NTg3MDQ0MzkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=='); // Expires Sept 2025

export default function TaskTable({ history, tasks, isTeam = false }) {

  const rows: GridRowsProp = 
  
  tasks.map((item, index) => {
    let action = "";
    if(item._task !== undefined) {
      action = `/matters/${item._matter._id}/tasks/${item._task._id}`;
    }else if (item.sync_status === "[Synced]") {
      action = `/contacts/${item._contact._id}/matters/${item._matter._id}`;
    }
    return {
      ...item,
      matter_number: item.display_number ? item.display_number : item.client_reference,
      id: index,
      // due_at: item.due_at && (item.due_at !== "No Due Date" ? dayjs(item.due_at).format("DD/MM/YYYY") : item.due_at),
      // due_at: item.due_at,
      due_at: item.due_at ? dayjs(item.due_at).format("DD/MM/YYYY") : "No Due Date",
      action
    }
  });
  
  /*
  [
    { id: 1, col1: 'Hello', col2: 'World' },
    { id: 2, col1: 'DataGridPro', col2: 'is Awesome' },
    { id: 3, col1: 'MUI', col2: 'is Amazing' },
  ]; */
  
  const columns: GridColDef[] = [

    // { headerName: "Matter Number", field: "matter_number", width: 150, pinnable: false },
    {
      field: 'matter_number',
      headerName: 'Matter Number',
      width: 160, pinnable: false,
      renderCell: (params) => {
        const url = '/matters/'+params.row._matter._id;
        return (
          <>
          {params.formattedValue}&nbsp;
          <Button
            colour="action"
            onClick={() => history.push(url)}
            icon={GiMagnifyingGlass}
          />
          </>
        );
      },
    },
    { headerName: "Client Name", field: "matter_name" , width: 300, pinnable: false},
    { headerName: "Matter Description", field: "description" , width: 200, pinnable: false},
    { headerName: "Task Details", field: "name" , width: 300, pinnable: false},
    { headerName: "Due Date", field: "due_at" , width: 200, pinnable: false},
    { headerName: "Assigned", field: "assignee_name" , width: 200, pinnable: false},
    {
      field: 'actions',
      headerName: '',
      width: 60,
      renderCell: (params) => {
        const url = params.row.action;
        return (
          <Button
            colour="action"
            onClick={() => history.push(url)}
            icon={GiMagnifyingGlass}
          />
        );
      },
    }
  ];

  return (
    <div style={{ height: 500, width: '100%', background: 'white' }}>
      <DataGridPro rows={rows} columns={columns} initialState={{ pinnedColumns: { left: ['matter_number'], right: ['actions'] } }} />
    </div>
    );
    return (
      <>

    <br/>

    <BasicTable
      header={[
        // { text: "ID", value: "id" },
        { text: "Matter Number", value: "matter_number" },
        { text: "Client Name", value: "matter_name" },
        // { text: "Synced", value: "sync_status" },
        { text: "Matter Description", value: "description" },
        { text: "Task Details", value: "name" },
        { text: "Due Date", value: "due_at" },
        { text: "Assigned", value: "assignee_name" },
      ]}
      body={tasks.map((item) => ({
        ...item,
        sync_status: item._matter !== undefined ? "[Synced]" : "[Not Synced]",
        matter_number: item.display_number ? item.display_number : item.client_reference,
        due_at: item.due_at && (item.due_at !== "No Due Date" ? dayjs(item.due_at).format("DD/MM/YYYY") : item.due_at) 
      }))}
      noDataMessage={"No Tasks"}
      action={(item) => {
        if(item._task !== undefined) {
          history.push(`/matters/${item._matter._id}/tasks/${item._task._id}`);
        }else if (item.sync_status === "[Synced]") {
          history.push(
            `/contacts/${item._contact._id}/matters/${item._matter._id}`
          );
        }
      }}
    />

    </>
    
  );
}
