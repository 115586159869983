import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "../../components/NotFound";
import Dash from "./dash";

export default function AreaTasksSettings({history, match}) {
  const routePrefix = "/all_fees";

  return (
    <>
      <Switch>
        <Route exact path={`${routePrefix}`} component={Dash} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
    
  );
}
