import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import { PlusCircleIcon, QuestionMarkCircleIcon, TrashIcon } from "@heroicons/react/solid";
import { Button } from "../../../components/Forms";
import { DataGridPro } from "@mui/x-data-grid-pro";
import API from "../../../api";
import Segment from "../../../components/Segment";
import Loader from "../../../components/Loader";
import { GiChecklist } from "react-icons/gi";
import { BiCheckCircle, BiPencil, BiXCircle } from "react-icons/bi";
import TasksBreadcrumbs from "./_components/tasksBreadcrumbs";

// export default RenderTaskModal => (theArea = [], theDetailsTask = null, areaRemove, areaAdd, saveArea, openLinksModal, documentTemplates = [], documentTemplatesTypes = [], showArchived = false) => {

export default function AreaTasksSettingsViewTask ({match, history}){

    const [area, setArea] = useState([]);
    const [detailsTask, setDetailsTask] = useState(null);
    const [rows, setRows] = useState([]);

    const [stepAutomation, setStepAutomation] = useState([]);
    const [automationModal, setAutomationModal] = useState(null);

    const [showArchived, setShowArchived] = useState(false);
    const [loading, setLoading] = useState(true);

    const _params = useParams();

    const areaRemove = (id) => {
      API.settings.area_tasks.updateStep(id, {archived: true}).then(() => {
        getData();
      })
    }

    const saveArea = () => {
      setLoading(true);
      API.settings.area_tasks
        .updateTask(_params.task,task)
        .then((res) => {
        if (res.success) {
            toast.success(res.message);
            getData();
        } else {
            toast.error(res.message);
        }
        setLoading(false);
        })
        .catch((err) => {
            console.log("🚀 ~ saveArea ~ err.response.data.message:", err.response.data.message)
        });
    };

    const archiveArea = () => {
      setLoading(true);
      API.settings.area_tasks
        .updateTask(_params.task,{
          archived: !task.archived
        })
        .then((res) => {
        if (res.success) {
            toast.success(task.archived ? "Un-Archived Successfully!" : "Archived Successfully!");
            getData();
        } else {
            toast.error(res.message);
        }
        setLoading(false);
        })
        .catch((err) => {
            console.log("🚀 ~ saveArea ~ err.response.data.message:", err.response.data.message)
        });
    }

    const areaAdd = () => {

      setLoading(true);

      saveArea();

      API.settings.area_tasks.addStep(_params.task).then(res => {
        if(res.success){
          toast.success(res.message);
          history.push(`/settings/area_tasks/${_params.id}/${_params.task}/${res._step}`)
        }else{
          toast.error(res.message)
        }
      })

    };

    let taskTypes = ["Task", "YesNoOption"];

    const getData = () => {
      API.settings.area_tasks
        .get(_params.id)
        .then((res) => {
            setArea(res);
        });

      API.settings.area_tasks
      .getTask(_params.task)
      .then((res) => {

          setDetailsTask(res);

          setRows(res ? res.steps
              .filter((e) => (showArchived ? true : !e.archived))
              .map((step, index) => ({
              id: index,
              ...step,
              })) : [])

          setLoading(false);

      })
    }

    useEffect(() => {
       getData();
    },[loading])

    let task = detailsTask;

    const handleChange = (e) => {

        setDetailsTask({
            ...detailsTask,
            [e.target.name] : e.target.value
        })

    }

    const handleStepChange = (e, id) => {
        let step = rows.filter(i => i._id == id);

        if(step[0]){
            setRows(rows.map(r => {
                if(r._id === id){
                    return {
                        ...r,
                        [e.target.name] : e.target.value
                    }
                }else{
                    return r;
                }
            }));
        }

    }

    const handleRowOrderChange = (params) => {
      const { oldIndex, targetIndex } = params;
      
      const newRows = [...rows];
      const [movedRow] = newRows.splice(oldIndex, 1);
      newRows.splice(targetIndex, 0, movedRow);
    
      const updatedSteps = newRows.map((row, index) => ({ ...row, order: index + 1 }));
    
      const updatedTasks = area.tasks.map((t) => {
        if (t._id === detailsTask) {
          return {
            ...t,
            steps: updatedSteps,
          };
        }
        return t;
      });
    
      setArea({ ...area, tasks: updatedTasks });
    };
      
    const openAutomationModal = (task) => {
        setAutomationModal(task._id);
        setStepAutomation(task.automations)
      }

    const columns = [
      {
        field: 'title',
        headerName: 'Title',
        flex: 1,
        headerClassName: 'center-header', // Center-align header
        sortable: false, // Disable sorting
        renderCell: (params) => (
          <>
            {params.row.archived && "(Archived)"}
            {params.row.title}
          </>
        ),
      },
      {
        field: 'dueDays',
        headerName: 'Step Due Days',
        flex: 1,
        type: 'number',
        headerClassName: 'center-header',
        sortable: false,
      },
      {
        field: 'chargeFee',
        headerName: 'Charge Fee',
        flex: 1,
        headerClassName: 'center-header',
        sortable: false,
        renderCell: (params) =>  params.row.chargeFee ? <><BiCheckCircle className="w-10 h-10 text-primary" /> {params.row.chargeFeeType} {`${params.row.chargeFeeType == "upfront" ? `- £${params.row.chargeFeeAmount}` : ""}`} </> : <BiXCircle className="w-10 h-10 text-primary" />,
      },
      {
        field: 'sendAccounts',
        headerName: 'Send to Accounts',
        flex: 1,
        headerClassName: 'center-header',
        sortable: false,
        renderCell: (params) =>  params.row.sendAccounts ? <BiCheckCircle className="w-10 h-10 text-primary" /> : <BiXCircle className="w-10 h-10 text-primary" />,
      },/*
      {
        field: 'selectCourt',
        headerName: 'Select Court',
        flex: 1,
        headerClassName: 'center-header',
        sortable: false,
        renderCell: (params) =>  params.row.selectCourt ? <BiCheckCircle className="w-10 h-10 text-primary" /> : <BiXCircle className="w-10 h-10 text-primary" />,
      },*/
      {
        field: 'corporateDependant',
        headerName: 'Corporate',
        flex: 1,
        headerClassName: 'center-header',
        sortable: false,
        renderCell: (params) =>  params.row.corporateDependant ? <BiCheckCircle className="w-10 h-10 text-primary" /> : <BiXCircle className="w-10 h-10 text-primary" />,
      }, 
      {
        field: 'contactDependant',
        headerName: 'Individual',
        flex: 1,
        headerClassName: 'center-header',
        sortable: false,
        renderCell: (params) =>  params.row.contactDependant ? <BiCheckCircle className="w-10 h-10 text-primary" /> : <BiXCircle className="w-10 h-10 text-primary" />,
      }, 
      {
        field: 'stepType',
        headerName: 'Type',
        flex: 1,
        headerClassName: 'center-header',
        sortable: false,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 200,
        headerClassName: 'center-header',
        sortable: false,
        renderCell: (params) => (
          <>
           <Button
              colour={"alert"}
              label={
                <TrashIcon className="h-4" />
              }
              onClick={(e) => {
                    e.preventDefault();
                    areaRemove(params.row._id)
                }
              }
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent ml-3 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            />
            <Button
              colour={"action"}
              label={
                <BiPencil className="h-4" />
              }
              onClick={(e) => {
                    history.push(`/settings/area_tasks/${_params.id}/${_params.task}/${params.row._id}`)
                }
              }
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent ml-3 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            />
            {/*  
            <button
            onClick={(e) => {
                console.log("🚀 ~ AreaTasksSettingsViewTask ~ params.row):", params.row)
                    e.preventDefault();
                    openLinksModal(params.row)
                }
            }
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent ml-3 bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          ><LinkIcon className="h-4" /></button>*/}
          </>
        )
      },
    ];


    const renderAutomationModal = () => {

        const handleClose = () => {
          setAutomationModal(null);
        };
    
        const handleModalSubmit = () => {
          API.settings.area_tasks.updateTaskAutomations(_params.id,task._id,stepAutomation).then((res) => {
            if(res.success) {
              setAutomationModal(null);
            //   getData();
              toast.success("Automations updated!")
            }
          })
        };
        
    
        // let task = area.tasks.filter(t => t._id === automationModal)   
    
        let automations = stepAutomation ? stepAutomation : [];
    
        let task_feilds = [
          {value: "task_completed", text: "Task Completed", type: "boolean"},
        ];

        let matter_feilds = [
          {value: "display_number", text: "Display Number", type: "alphabetic"},
          {value: "description", text: "Description", type: "alphabetic"},
          {value: "paymentTerms", text: "Payment Terms", type: "numeric"},
          {value: "practice_area", text: "Practice Area", type: "alphabetic"},
          {value: "billing_method", text: "Billing Method", type: "alphabetic"},
          {value: "billing", text: "Billing", type: "alphabetic"},
          {value: "responsible_attorney", text: "Fee Earner", type: "alphabetic"},
          {value: "originating_attorney", text: "Supervisor", type: "alphabetic"},
          {value: "interest_rate", text: "Interest Rate", type: "numeric"},
          {value: "feeRates", text: "Fee Rates", type: "alphabetic"}, //??
          {value: "debtors", text: "Has Debtors", type: "alphabetic"},
        ];
    
        let contact_feilds = [
          {value: "name", text: "Company Name / Ind. Name", type: "alphabetic"},
          {value: "registrationNo", text: "Registration Number", type: "alphabetic"},
          {value: "clientRef", text: "Client Reference", type: "alphabetic"},
          {value: "addresses", text: "Primary Address", type: "alphabetic"},
          {value: "emails", text: "Primary Email Address", type: "alphabetic"},
          {value: "phone", text: "Primary Phone Number", type: "alphabetic"},
          {value: "website", text: "Primary Website", type: "alphabetic"},
        ];
    
        return (
          <Transition.Root show={automationModal !== null } as={Fragment}>
            <Dialog
              as="div"
              className="fixed z-10 inset-0 overflow-y-auto"
              onClose={handleClose}
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:py-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
    
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  
                  <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
                  <div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900"
                        >
                          Automations for Task: {task.title}
                        </Dialog.Title>
                        <div className="mt-4">
                          <div className={`text-lg`}>
                            <table
                              className={`min-w-full divide-y divide-gray-300 border border-gray-300 table-fixed`}
                            >
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                                  >
                                    Task Step
                                  </th>
                                  <th
                                    scope="col"
                                    className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                                  >
                                    Automation Query
                                  </th>
                                  <th
                                    scope="col"
                                    className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                                  >
                                    <button
                                          type="button"
                                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                                          onClick={() => {
                                            automations.push({step: "", query: [],  actions: []})
                                            setStepAutomation([].concat(automations))
                                          }}
                                        >
                                      <PlusCircleIcon className="h-4 inline-block"/>
                                    </button>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-white divide-y divide-gray-300">
                                {automations.length > 0 ? (
                                  automations.map((item, index) => 
                                  {
    
                                    if(!automations[index].actions){
                                      automations[index].actions = [
                                        {action: "task_completed"}
                                      ];
                                    }
    
                                    return(
                                    <>
                                    <tr
                                      className={`hover:bg-gray-100 focus:bg-gray-100`}
                                      key={`autos_${index}`}
                                    >
                                      <td className="px-4 py-4">
    
                                        <select
                                          id="step"
                                          name="step"
                                          class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
                                          onChange={(e) => {
                                            automations[index].step = e.target.value;
                                            setStepAutomation([].concat(automations))
                                          }}
                                        >
                                          <option disabled selected={!item.step}>Select Step</option>
                                          {task.steps.filter(t => t.archived !== true).map((t, ti) => {
                                            return (
                                              <option key={`steps_${ti}`} selected={item.step == t._id} value={t._id}>
                                                {t.title}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </td>
                                      <td className="px-4 py-4">
                                        {item.step && <>
                                        {automations[index].query.length > 0 ? automations[index].query.map((a, ind) => {
    
                                          let feild_map = [];
                                          switch(automations[index].query[ind].type){
                                            case "task":
                                              feild_map = task_feilds;
                                            break;
                                            case "matter":
                                              feild_map = matter_feilds;
                                            break;
                                            case "contact":
                                              feild_map = contact_feilds;
                                            break;
                                          }
    
                                          return (<span>
    
                                            {ind > 0 && <select 
                                             onChange={(e) => {
                                              automations[index].query[ind].opperand = e.target.value
                                              setStepAutomation([].concat(automations))
                                            }}
                                            className="block flex-1 rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm">
                                              <option selected={a.operand === "AND"} value="AND">AND</option>
                                              <option selected={a.operand === "OR"} value="OR">OR</option>
                                            </select>}
    
                                            <select 
                                            onChange={(e) => {
                                              automations[index].query[ind].type = e.target.value
                                              setStepAutomation([].concat(automations))
                                            }}
                                            className="inline-block flex-1 rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm">
                                              <option disabled selected={!a.type}>Select a Type</option>
                                              <option selected={a.type === "task"} value="task">Task</option>
                                              <option selected={a.type === "matter"} value="matter">Matter</option>
                                              <option selected={a.type === "contact"} value="contact">Contact</option>
                                              <option selected={a.type === "debtor"} value="debtor">Debtor</option>
                                            </select>
    
                                            <select
                                            onChange={(e) => {
                                              automations[index].query[ind].field = e.target.value
                                              setStepAutomation([].concat(automations))
                                            }}
                                            className="inline-block flex-1 rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm">
                                              <option disabled selected={!a.field}>Select a Field</option>
                                              {feild_map.map((f, fi) => {
                                                return (
                                                  <option key={`field_${fi}`} selected={automations[index].query[ind].field == f.value} value={f.value}>
                                                    {f.text}
                                                  </option>
                                                );
                                              })}
                                            </select>
    
                                            {a.type !== "task" && a.type && a.field && <select 
                                            onChange={(e) => {
                                              automations[index].query[ind].search = e.target.value
                                              setStepAutomation([].concat(automations))
                                            }}
                                            className="inline-block flex-1 rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm">
                                              <option disabled selected={!a.search}>Select a Search</option>
                                              <option selected={a.search === "exists"} value="exists">Exists</option>
                                              <option selected={a.search === "lessthan"} value="lessthan">&lt;</option>
                                              <option selected={a.search === "lessthanequal"} value="lessthanequal">&lt;=</option>
                                              <option selected={a.search === "equal"} value="equal">=</option>
                                              <option selected={a.search === "greaterthanequal"} value="greaterthanequal">&gt;=</option>
                                              <option selected={a.search === "greaterthan"} value="greaterthan">&gt;</option>
                                            </select>}
                                            {(a.search !== "exists" && a.search !== "") &&
                                            <input 
                                            onChange={(e) => {
                                              automations[index].query[ind].search_extra = e.target.value
                                              setStepAutomation([].concat(automations))
                                            }}
                                            // type="number" 
                                            type="text" 
                                            className="inline-block rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm" 
                                            value={a.search_extra}/>}
                                            <button
                                              type="button"
                                              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                              onClick={() => {
                                                automations[index].query.splice(ind, 1);
                                                setStepAutomation([].concat(automations))
                                              }}
                                            >
                                            -
                                            </button>
                                          </span>)
                                        }) : "No Query"}
                                          <button 
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => {
                                              automations[index].query.push({
                                                operand: automations[index].query.length > 0 ? "AND" : "", type: "", field: "", search: ""
                                              })
                                              setStepAutomation([].concat(automations))
                                            }}
                                          >+</button>
                                        </>}
                                        
                                        
                                      </td>
                                      <td className="px-4 py-4">
                                        <button
                                          type="button"
                                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                          onClick={() => {
                                            automations.splice(index, 1);
                                            setStepAutomation([].concat(automations))
                                          }}
                                        >
                                        <TrashIcon className="h-4 inline-block"/>
                                        </button>
                                      </td>
                                    </tr>
                                    <tr
                                      className={`hover:bg-gray-100 focus:bg-gray-100`}
                                      key={`autos_${index}`}
                                    >
                                      <td className="px-4 py-4">
    
                                        Actions for Step 
                                      </td>
                                      <td className="px-4 py-4">
                                        {item.step && <>
                                        {automations[index].actions.length > 0 ? automations[index].actions.map((a, ind) => {
    
                                          let feild_map = [
                                            {value: "lba_completed", text: "Mark LBA as Completed"},
                                            {value: "task_completed", text: "Mark Task as Completed"},
                                            {value: "stage_completed", text: "Mark Matter Stage as Completed"},
                                            {value: "matter_milestone_update", text: "Matter Milestone Update"},
                                          ];
    
                                          let milestones_map = [
                                            {value: "prepared", text: "Claim Prepared"},
                                            {value: "sent", text: "Claim Sent to Court"},
                                            {value: "issue", text: "Date of Issue"},
                                            {value: "judgement", text: "Date of Judgment"},
                                            {value: "judgement_received", text: "Judgment Received On"},
                                            {value: "lba_sent", text: "LBA Sent"},
                                            {value: "closed", text: "Case Closed"},
                                          ];
    
                                          return (<span style={{display: "block"}}>
                                            <select
                                              onChange={(e) => {
                                                automations[index].actions[ind].action = e.target.value
                                                setStepAutomation([].concat(automations))
                                              }}
                                              className="inline-block flex-1 rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm">
                                                <option disabled selected={!a.field}>Select action</option>
                                                {feild_map.map((f, fi) => {
                                                  return (
                                                    <option key={`action_${fi}`} selected={automations[index].actions[ind].action == f.value} value={f.value}>
                                                      {f.text}
                                                    </option>
                                                  );
                                                })}
                                            </select>
    
                                            {automations[index].actions[ind].action === "matter_milestone_update" && 
                                            
                                              <select
                                                onChange={(e) => {
                                                  automations[index].actions[ind].option = e.target.value
                                                  setStepAutomation([].concat(automations))
                                                }}
                                                className="inline-block flex-1 rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm">
                                                  <option disabled selected={!a.field}>Select Milestone</option>
                                                  {milestones_map.map((f, fi) => {
                                                    return (
                                                      <option key={`milestone_${fi}`} selected={automations[index].actions[ind].option == f.value} value={f.value}>
                                                        {f.text}
                                                      </option>
                                                    );
                                                  })}
                                              </select>
    
                                            }
    
                                            <button
                                              type="button"
                                              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                              onClick={() => {
                                                automations[index].actions.splice(ind, 1);
                                                setStepAutomation([].concat(automations))
                                              }}
                                            >
                                            -
                                            </button>
                                          </span>)
                                        }) : "No Actions selected, action will Mark Completed only!"}
                                          <button 
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => {
                                              automations[index].actions.push({
                                                type: "", field: "", search: ""
                                              })
                                              setStepAutomation([].concat(automations))
                                            }}
                                          >+</button>
                                        </>}
                                        
                                        
                                      </td>
                                      <td className="px-4 py-4">
                                      </td>
                                    </tr>
                                    </>
                                    );
                                  })
                                  
                                ) : (
                                  <tr className={`text-black `}>
                                    <td colSpan={5} className="px-4 py-4">
                                      No Automations...
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>Note: Remember to Save the Task, closing the Automations will not save them you still need to save the Task before leaving the screen!</p>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={handleModalSubmit}
                      >
                       Finish
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={handleClose}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        );
      }

    if(loading){
      return <Loader />;
    }

    return (
      <>
      <TasksBreadcrumbs panes={[
        { name: `Area - ${area && area.area && area.area.area}`, href: `/settings/area_tasks/${area && area.area && area.area._id}`, current: false},
        { name: `Task - ${task && task.title}`, href: `#`, current: true},
      ]} />

      <Segment>
          
            {task && renderAutomationModal()}
            <div>
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-3">
                        <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Task Details: {task && task.title}
                        </h3>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-3 md:mt-0">
                        <div className="mt-4">
                        <div className={`text-lg`}>
                            {task && <>
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none w-full">
                                  <dt className="text-sm font-medium text-gray-500">
                                      Title
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                      <input
                                      type="text"
                                      value={task.title}
                                      onChange={handleChange}
                                      name="title"
                                      id="title"
                                      className="block flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm w-full"
                                      />
                                  </dd>
                                </div>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Order
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <input
                                        value={task.order}
                                        onChange={handleChange}
                                        type="number"
                                        name="order"
                                        id="order"
                                        className="block flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                                        />
                                    </dd>
                                    </div>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none ">
                                    <dt className="text-sm font-medium text-gray-500 has-tooltip">
                                        Task Type <QuestionMarkCircleIcon className="h-4 inline-block" />
                                    <span class="tooltip rounded shadow-lg p-1 bg-gray-100 -mt-8">
                                        Types:
                                        <ul>
                                        <li>Task: Ordinary Task with its own steps</li>
                                        <li>YesNoOption: Task with Yes/No option which will show the task and steps of Yes or No result</li>
                                        </ul>
                                    </span>
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <select
                                        id="taskType"
                                        name="taskType"
                                        class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
                                        onChange={handleChange}
                                        >
                                        {taskTypes.map((t) => {
                                            return (
                                            <option selected={task.taskType == t}>
                                                {t}
                                            </option>
                                            );
                                        })}
                                        </select>
                                    </dd>
                                    </div>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none ">
                                      <dt className="text-sm font-medium text-gray-500 has-tooltip">
                                          Individuals <QuestionMarkCircleIcon className="h-4 inline-block" />
                                          <span class="tooltip rounded shadow-lg p-1 bg-gray-100 -mt-8">
                                          Individual: Show step for Individuals?
                                          </span>
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <input
                                            checked={
                                            task.contactDependant
                                            }
                                            onChange={(e) => {
                                                setDetailsTask({
                                                    ...detailsTask,
                                                    contactDependant : !task.contactDependant
                                                })
                                            }}
                                            id="contactDependant"
                                            name="contactDependant"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                        />
                                      </dd>
                                    </div>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none ">
                                      <dt className="text-sm font-medium text-gray-500 has-tooltip">
                                          Corporate <QuestionMarkCircleIcon className="h-4 inline-block" />
                                          <span class="tooltip rounded shadow-lg p-1 bg-gray-100 -mt-8">
                                          Corporate: Show step for Corporates?
                                          </span>
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <input
                                            checked={
                                            task.corporateDependant
                                            }
                                            onChange={(e) => {
                                                setDetailsTask({
                                                    ...detailsTask,
                                                    corporateDependant : !task.corporateDependant
                                                })
                                            }}
                                            id="corporateDependant"
                                            name="corporateDependant"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                        />
                                      </dd>
                                    </div>
                                    {/*  
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none ">
                                      <dt className="text-sm font-medium text-gray-500 has-tooltip">
                                            Run Automation on Completion <QuestionMarkCircleIcon className="h-4 inline-block" />
                                          <span class="tooltip rounded shadow-lg p-1 bg-gray-100 -mt-8">
                                            Run the Automations of Task Query when Task is Completed.
                                          </span>
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <input
                                            checked={
                                            task.runAutomations
                                            }
                                            onChange={(e) => {
                                                setDetailsTask({
                                                    ...detailsTask,
                                                    runAutomations : !task.runAutomations
                                                })
                                            }}
                                            id="runAutomations"
                                            name="runAutomations"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                        />
                                      </dd>
                                    </div>*/}
                                    {task.taskType !== "YesNoOption" &&
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none">
                                      <dt className="text-sm font-medium text-gray-500">
                                          Task Due Days
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                          <input
                                          value={task.dueDays}
                                          onChange={handleChange}
                                          type="number"
                                          name="dueDays"
                                          id="task_dueDays"
                                          className="block flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                                          />
                                      </dd>
                                    </div>}
                                    {task.taskType == "YesNoOption" ? (
                                    <>
                                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none ">
                                          <dt className="text-sm font-medium text-gray-500 has-tooltip">
                                              Include in Automations List <QuestionMarkCircleIcon className="h-4 inline-block" />
                                              <span class="tooltip rounded shadow-lg p-1 bg-gray-100 -mt-8">
                                              For Matter Stage Fees, show this Task under the list of Automations related to Stage completion.
                                              </span>
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            <input
                                                checked={task.includedInAutomations}
                                                onChange={(e) => {
                                                    setDetailsTask({
                                                        ...detailsTask,
                                                        includedInAutomations : !task.includedInAutomations
                                                    })
                                                }}
                                                id="includedInAutomations"
                                                name="includedInAutomations"
                                                type="checkbox"
                                                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                            />
                                          </dd>
                                        </div>
                                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Tasks
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            <div class="mt-8 flex flex-col">
                                            <div class="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                    <table class="min-w-full divide-y divide-gray-300">
                                                    <thead class="bg-gray-50">
                                                        <tr>
                                                        <th
                                                            scope="col"
                                                            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                        >
                                                            Option
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                        >
                                                            Task
                                                        </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="divide-y divide-gray-200 bg-white">
                                                        {["Yes", "No"].map((o) => {
                                                        return (
                                                            <tr>
                                                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                {o}
                                                            </td>
                                                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                <select
                                                                id="YesNoOption"
                                                                name={`YesNoOption_${o}`}
                                                                class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
                                                                onChange={handleChange}
                                                                >
                                                                {area.tasks && area.tasks
                                                                    .filter((e) =>
                                                                    showArchived
                                                                        ? true
                                                                        : !e.archived &&
                                                                        e._id !==
                                                                            task._id
                                                                    )
                                                                    .sort((a, b) => (a.order > b.order ? 1 : -1))
                                                                    .map((t) => {
                                                                    return (
                                                                        <option value={t._id} selected={t._id == task[`YesNoOption_${o}`]}>
                                                                        ({t.order}) {t.title}
                                                                        </option>
                                                                    );
                                                                    })}
                                                                </select>
                                                            </td>
                                                            </tr>
                                                        );
                                                        })}
                                                    </tbody>
                                                    </table>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </dd>
                                        </div>
                                    </>
                                    ) : (
                                    <>
                                        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Steps (
                                            {
                                            task.steps.filter((e) =>
                                                showArchived ? true : !e.archived
                                            ).length
                                            }
                                            )
                                            {!task.archived && (
                                            <button
                                                onClick={() =>
                                                // areaAdd("step", index)
                                                areaAdd(task._id)
                                                }
                                                type="submit"
                                                className="inline-block justify-center rounded-md border border-transparent ml-3 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                            >
                                                Add
                                            </button>
                                            )}
                                            { task.steps.filter((e) =>
                                                showArchived ? true : !e.archived
                                            ).length > 0 &&
                                            <button
                                            onClick={() =>
                                                openAutomationModal(task)
                                            }
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent ml-3 bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                                            ><GiChecklist className="h-4" /> Automations</button>}
                                        </dt>
                                        </div>
                                        <div className="mb-2">
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            <div class="mt-8 flex flex-col">
                                            <div class="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                                <div class=" shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                    {loading ? <Loader /> :
                                                    <DataGridPro
                                                    rows={rows}
                                                    columns={columns}
                                                    disableColumnMenu
                                                    onProcessRowUpdate={(newRow) => {
                                                        setRows((prevRows) =>
                                                        prevRows.map((row) => (row.id === newRow.id ? { ...row, ...newRow } : row))
                                                        );
                                                        handleStepChange(newRow);
                                                        return newRow;
                                                    }}
                                                    rowHeight={120}
                                                    rowReordering
                                                    onRowOrderChange={handleRowOrderChange}
                                                    />}
                                                </div>
                                                </div>
                                            </div>
                                            </div>

                                        </dd>
                                        </div>
                                    </>
                                    )}
                                    </>}
                        </div>
                        </div>
                    </div>
                    <div className="md:col-span-3 border-t border-gray-200 px-4 py-5 sm:p-0">
                      <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                        <button
                          onClick={() => history.push(`/settings/area_tasks/${_params.id}`)}
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent mr-3 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                        >
                          Back
                        </button>
                        <button
                          onClick={saveArea}
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent mr-3 bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        >
                          Save
                        </button>
                        <button
                          onClick={archiveArea}
                          type="submit"
                          className={`inline-flex justify-center rounded-md border border-transparent bg-${task.archived ? "warning" : "alert"} py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-${task.archived ? "warning" : "alert"} focus:outline-none focus:ring-2 focus:ring-${task.archived ? "warning" : "alert"} focus:ring-offset-2`}
                        >
                          {task.archived && "Un-"}Archive
                        </button>
                      </div> 
                    </div>
                </div>
            </div>
        </Segment>

      </>
        );
  }