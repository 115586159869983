import React from "react";
import Segment from "../../../components/Segment";
import ChainedContext from "../../../contexts/chainedContext";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import DebtorsForm from "./_components/debtorsForm";
import API from "../../../api";
import MatterBreadcrumbs from "../_components/MatterBreadcrumbs";

const AddDebtors = ({matter, history}) => {
    const handleSubmit = data => {
        API.matters.debtors.create(matter._id, data).then((res) => {
            if(res.success) {
                /*
                let debtsAdded = 0;

                data.debts.map((debt) => {
                    API.matters.debtors.debt
                    .create(matter._id, res._id, {
                        ...debt,
                        debtor:res._id
                    })
                    .then((r) => {
                        debtsAdded++;
                    });
                }) */
                history.push(`/matters/${matter._id}/debtors`);
                window.location.reload();
                
            }else{
                console.log("error creating Debtor", res);
            }
            
        }).catch((err) => {
            console.log(err);
        });
    }
    return (
        <div>
            <MatterBreadcrumbs panes={[
                    { name: `Client - ${matter.contact && `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}`}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
                    { name: `Matter - ${matter.display_number}`, href: `/matters/${matter._id}`, current: false },
                    { name: "Debtors", href: `/matters/${matter._id}/debtors`, current: false },
                    { name: "Add", href: '#', current: true },
                ]} />
            <Segment className="mt-2">
                <div>
                    <h3 className="text-xl leading-6 font-medium text-primary">
                        <span className="my-auto ">Add Debtor</span>
                    </h3>
                    <div className="w-full border-t border-gray-300 my-2"/>
                </div>
                <DebtorsForm addDebtor={false} contact={matter.contact} onSubmit={handleSubmit} onCancel={() => history.push(`/matters/${matter._id}/debtors`)} add={true}/>
            </Segment>
        </div>
    );
}
export default ChainedContext(AddDebtors, [
    [MatterReceiver, "matter"],
]);