import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Segment from "../../../../components/Segment";
import Add_Debt_Form from "./_forms/Add_Debt_Form";
import { useParams } from "react-router-dom";

import ChainedContext from "../../../../contexts/chainedContext";
import MatterReceiver from "../../../../contexts/matter_context/Matter_Receiver";
import DebtorReceiver from "../../../../contexts/debtor_context/Debtor_Receiver";
import API from "../../../../api";
import DebtorBar from "../_components/DebtorBar";
const BaseRate = [
  { date: dayjs("2018-08-03 00:00:00"), rate: 0.75 },
  { date: dayjs("2020-03-11 00:00:00"), rate: 0.25 },
  { date: dayjs("2020-03-19 00:00:00"), rate: 0.1 },
];
function EditDebt({ matter, debtor, history }) {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [baseRate, setBaseRate] = useState({});

  useEffect(() => {
    if(loading)
      API.settings.base_rates.normal.get().then((data) => {
        if(data[0]){
          setBaseRate(data[0]);
        }
        setLoading(false);
      });
  }, [baseRate]);
  const handleData = (data) => {
    let formData = data;
    formData.matter = matter._id;
    formData.debtor = debtor._id;
    API.matters.debtors.debt
      .edit(matter._id, debtor._id, params.debt_id, formData)
      .then((res) => {
        history.push(`/matters/${params.matter_id}/debtors/${params.debtor_id}/debt/${params.debt_id}`);
      });
  };
  return (
    <div className="w-full  ">
      <Segment>
        <DebtorBar debtor={debtor} contact={matter.contact} />
        <Add_Debt_Form
          validateSubmit={(data) => {}}
          data={handleData}
          contact_settings={matter.contact.settings}
          matter_settings={{paymentTerms: matter.paymentTerms, interest_type: matter.invoice_interest_type ? matter.invoice_interest_type : (matter.contact.settings && matter.contact.settings.invoice_interest_type ? matter.contact.settings.invoice_interest_type : "")}}
          interest_rate={matter.interest_rate ? matter.interest_rate : (matter.contact.interest_rate ? matter.contact.interest_rate : (baseRate ? baseRate.rate : 0)) }
          edit
        />
        {/* <DebtForm BaseRate={BaseRate} submit={handleSubmit} update={debt} /> */}
      </Segment>
    </div>
  );
}
export default ChainedContext(EditDebt, [
  [MatterReceiver, "matter"],
  [DebtorReceiver, "debtor"],
]);
