import React, { useState, useEffect, Fragment } from "react";
import {
  Input,
  Dropdown,
  TextArea,
  Group,
  Button,
  SelectTwo,
  DateSelect,
  SelectZero,
} from "../../../components/Forms";
import API from "../../../api";
import CommonFunctions from "../../../CommonFunctions";
import Segment from "../../../components/Segment";
import Header from "../../../components/Header";
import { CurrencyPoundIcon, MailIcon, PencilIcon, SaveAsIcon, XIcon } from "@heroicons/react/solid";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import ChainedContext from "../../../contexts/chainedContext";
import { GiMoneyStack } from "react-icons/gi";
import { Dialog, Transition } from "@headlessui/react";
import dayjs from "dayjs";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import { v4 as uuid } from "uuid";
import RatesTable from "../../../components/Tables/ratesTable";
import { InterestTypes } from "../../../_lists";

function EditMatter({ matter, history }) {
  const [data, setData] = useState({
    description: "",
    client_reference: "",
    location: "",
    practice_area: "",
    responsible_attorney: "",
    originating_attorney: "",
    interest_rate: 0,
    paymentTerms: 0,
    contingency_percentage: 0,
    custom_fields: {},
    "fee_set": "",
    "upfront_fee": 0,
    "upfront_fee_type": "fixed",
    "fee_thresholds": [], 
    billable: true,
    billing_method: "",
    xero_send: "manual",
  });
  const [contact, setContact] = useState({});
  const [clioUsers, setClioUsers] = useState([]);
  const [practiceArea, setPracticeArea] = useState([]);
  const [areaTasks, setAreaTasks] = useState([{text: "None", value: -1}]);
  const [mountLoading, setMountLoading] = useState(true);
  const [openInterestModal, setOpenInterestModal] = useState(false)
  const [interestModalStage, setInterestModalStage] = useState("");
  const [latePaymentsBaseRate, setLatePaymentsBaseRate] = useState({});
  const [baseRate, setBaseRate] = useState({});

  const [customFields, setCustomFields] = useState([]);

  const [feesModal, setFeesModal] = useState(false);
  const [showSelectSet, setShowSelectSet] = useState(false);
  const [feeSets, setFeeSets] = useState([]);

  const [tasks, setTasks] = useState([]);
  const [automations, setAutomations] = useState([]);

  const [updateArea, setUpdateArea] = useState(true);

  useEffect(() => {
    // matter._refresh();
  }, []);

  useEffect(() => {
    if(updateArea && data.areaTask && data.areaTask != -1){
        let tasks = [];

        API.settings.area_tasks.get(data.areaTask).then(res => {

          let autos = [];
          
          res.tasks.sort((a,b) => a.order > b.order ? 1 : -1).filter(t => t.archived !== true).map(t => {

            if(t.taskType == "YesNoOption" && t.includedInAutomations == true){
              autos.push({
                task: t._id,
                title: t.title
              })
            }

            t.automations.filter((au) => au.actions.some(action => action.action === "stage_completed")).map(a => autos.push(a))

          })

          tasks = res.tasks.sort((a,b) => a.order > b.order ? 1 : -1).filter(t => t.archived !== true).flatMap(s => 
            ({
              text: s.title,
              value: s._id,
              steps: s.steps
            })
          );
            
          setTasks(tasks);
          setAutomations(autos);
          setUpdateArea(false);
        });
    }
  }, [data]);

  useEffect(() => {
    if (matter && matter._id) {
      setData({
        ...matter,
        prev_upfront_fee: matter.upfront_fee,
        prev_upfront_fee_type: matter.upfront_fee_type,
        interest_rate: matter.interest_rate ? matter.interest_rate : (matter.contact ? matter.contact.settings.interest_rate ? matter.contact.settings.interest_rate : 0 : 0),
        paymentTerms: matter.paymentTerms ? matter.paymentTerms : (matter.contact ? matter.contact.settings.paymentTerms ? matter.contact.settings.paymentTerms : 0 : 0),
        // practice_area: matter.practice_area ? matter.practice_area._clioId : "",
        responsible_attorney: matter.responsible_attorney
          ? matter.responsible_attorney._clioId
          : "",
        originating_attorney: matter.originating_attorney
          ? matter.originating_attorney._clioId
          : "",
        fee_set: "",
      });

      setInterestModalStage(matter.interest_type ? matter.interest_type : (matter.contact ? matter.contact.settings.interest_type ? matter.contact.settings.interest_type : "" : ""));

      API.settings.base_rates.late_payments.get().then((res )=> {
        let f2 = res.filter(e => dayjs().diff(e.date) >= 0);

        if(f2[0]){
          setLatePaymentsBaseRate(f2[0]);
        }else{
          setLatePaymentsBaseRate(res[0]);
        }
      })

      API.settings.base_rates.normal.get().then((res )=> {
        let f2 = res.filter(e => dayjs().diff(e.date) >= 0);

        if(f2[0]){
          setBaseRate(f2[0]);
        }else{
          setBaseRate(res[0]);
        }
      })

      API.settings.fee_scales().list().then((res) => {
        setFeeSets(res);
      })

      API.settings.area_tasks.getAll().then(res => {
        const tasksBuilder = res.map((a) => {
          return {
            text: a.area,
            value: a._id,
            practice: a.practice
          };
        });

        setAreaTasks([{text: "None", value: -1}].concat(tasksBuilder));
      })

    }
  }, [matter]);

  useEffect(() => {
    if (mountLoading) {
      Promise.all([
        // API.settings.practice_areas().catch((e) => CommonFunctions.handleError(e)),
        API.settings.doc_types().list().catch((e) => CommonFunctions.handleError(e)),
        API.users.getAll().catch((e) => CommonFunctions.handleError(e)),
        API.customFields.list().catch((e) => CommonFunctions.handleError(e))
        // API.matters.get(params.id).catch((e) => CommonFunctions.handleError(e)),
      ])
        .then((res) => {
          if (res[0]) {
            setPracticeArea(
              res[0].filter(a => a.archived !== true).map((a, index) => ({
                key: index,
                text: a.name,
                value: a._id,
              }))
            );

            // if (
            //   practice_areas.find((item) => item.value === 885229) !== undefined
            // ) {
            //   setData({ ...data, practice_area: 885229 });
            // }
          }

          if (res[1]) {
            setClioUsers(
              res[1].users.map((a, index) => ({
                key: index,
                text: a.name,
                value: a._clioId,
                rate: a.rate
              }))
            );
          }

          if (res[2]) {
            setCustomFields(res[2].filter(e => e.type === "matter" && !e.archived));
          //   setData({
          //     ...data,
          //     ...res[2],
          //     practice_area: res[2].practice_area._clioId,
          //     originating_attorney: res[2].originating_attorney._clioId,
          //     responsible_attorney: res[2].responsible_attorney._clioId,
          //   });
          }
        })
        .finally(() => setMountLoading(false));
    }
  }, [mountLoading]);

  const onChange = (e) => {

    if(e.target.name === "areaTask"){
      setUpdateArea(true);
    }

    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    API.matters
      .update(matter._id, data)
      // .update(matter._id, {...data, interestType: interestModalStage})
      .then(() => {
        matter._refresh();
        history.push("/matters/" + matter._id);
      })
      .catch((e) => CommonFunctions.handleError(e))
      .finally(() => {});
  };

  const interestModal = () => {
    return (
      <Transition.Root show={openInterestModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpenInterestModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
  
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                      <GiMoneyStack className="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Interest Rate
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Contract with Interest Specified?
                          <span className="ml-2 isolate inline-flex rounded-md shadow-sm">
                            <button
                              type="button"
                              className={`${interestModalStage.includes("contractual") && "bg-primary"} relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => {setInterestModalStage("contractual"); setData({...data, interest_type: "contractual"})}}
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className={`${interestModalStage.includes("bussiness") && "bg-primary"} relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => {setInterestModalStage("bussiness"); setData({...data, interest_type: "bussiness"})}}
                            >
                              No
                            </button>
                          </span>
                        </p>
                        {interestModalStage && interestModalStage.includes("contractual") &&
                        <p className="text-sm text-gray-500 mt-2">
                          Specified flat rate?
                          <span className="ml-2 isolate inline-flex rounded-md shadow-sm">
                            <button
                              type="button"
                              className={`${interestModalStage.includes("contractual_flat") && "bg-primary"} relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => {setInterestModalStage("contractual_flat"); setData({...data, interest_rate: baseRate.rate, interest_type: "contractual_flat"})}}
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className={`${interestModalStage == "contractual_nonflat" && "bg-primary"} relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => {
                                setInterestModalStage("contractual_nonflat");
                                setData({
                                  ...data,
                                  interest_rate: parseFloat(data.interest_rate_percent ? data.interest_rate_percent : 0) + parseFloat(data.interest_rate_base ? data.interest_rate_base : baseRate.rate),
                                  interest_type: "contractual_nonflat"
                                })
                              
                              }}
                            >
                              No
                            </button>
                          </span>
                        </p>}
                        {interestModalStage && interestModalStage.includes("contractual_flat") &&
                        <p className="text-sm text-gray-500 mt-2">
                          Billing Type?
                          <span className="ml-2 isolate inline-flex rounded-md shadow-sm">
                            <button
                              type="button"
                              className={`${interestModalStage == "contractual_flat_monthly" && "bg-primary"} relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => {
                                setInterestModalStage("contractual_flat_monthly"); 
                                setData({
                                  ...data,
                                  interest_rate: baseRate.rate, 
                                  interest_type: "contractual_flat_monthly",
                                  invoice_interest_type: "contractual"
                                })
                              }}
                            >
                              Monthly
                            </button>
                            <button
                              type="button"
                              className={`${interestModalStage == "contractual_flat_annually" && "bg-primary"} relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => {
                                setInterestModalStage("contractual_flat_annually");
                                setData({
                                  ...data,
                                  interest_rate: baseRate.rate, 
                                  interest_type: "contractual_flat_annually",
                                  invoice_interest_type: "contractual"
                                })
                              
                              }}
                            >
                              Annually
                            </button>
                          </span>
                        </p>}
                        {interestModalStage && interestModalStage.includes("contractual_nonflat") &&
                        <p className="text-sm text-gray-500 mt-2">
                          Base Plus?
                          <span className="ml-2 isolate inline-flex rounded-md shadow-sm">
                            <button
                              type="button"
                              className={`${interestModalStage == "contractual_nonflat_plus" && "bg-primary"} relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => {
                                setInterestModalStage("contractual_nonflat_plus");
                                setData({
                                  ...data,
                                  interest_rate: baseRate.rate, 
                                  interest_type: "contractual_nonflat_plus",
                                  // invoice_interest_type: "contractual"
                                  invoice_interest_type: "base_rate_plus"
                                })
                              }}
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className={`${interestModalStage == "contractual_nonflat_base" && "bg-primary"} relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => {
                                setInterestModalStage("contractual_nonflat_base"); 
                                setData({
                                  ...data,
                                  interest_rate: baseRate.rate, 
                                  interest_type: "contractual_nonflat_base",
                                  // invoice_interest_type: "contractual"
                                  invoice_interest_type: "base_rate"
                                })
                              }}
                            >
                              No
                            </button>
                          </span>
                        </p>}
                        {/* {interestModalStage && (interestModalStage == "contractual_flat") && */}
                        {interestModalStage && (interestModalStage == "contractual_flat_monthly" || interestModalStage == "contractual_flat_annually") &&
                        <p className="text-sm text-gray-500 mt-2">
                          <Input
                            label="Flat Rate"
                            name="interest_rate"
                            value={data.interest_rate}
                            onChange={onChange}
                          />
                        </p>}
                        {interestModalStage && interestModalStage.includes("contractual_nonflat_") &&
                        <p className="text-sm text-gray-500 mt-2">
                          <Input
                            label="Base Rate"
                            name="interest_rate_base"
                            value={data.interest_rate_base ? data.interest_rate_base : baseRate.rate}
                            onChange={(e) => {
                              setData({ 
                                ...data, 
                                [e.target.name]: e.target.value,
                                interest_rate: parseFloat(e.target.value > 0 ? e.target.value : baseRate.rate) + parseFloat(data.interest_rate_percent)
                                });
                            }}
                          />
                          <Input
                            label="Percentage Specified"
                            name="interest_rate_percent"
                            value={data.interest_rate_percent ? data.interest_rate_percent : 0}
                            onChange={(e) => {
                              setData({ 
                                ...data, 
                                [e.target.name]: e.target.value,
                                interest_rate: parseFloat(e.target.value) + parseFloat(data.interest_rate_base ? data.interest_rate_base : baseRate.rate)
                                });
                            }}
                          />
                          
                          Calculated Rate: {data.interest_rate}
                        </p>}
                        {interestModalStage && interestModalStage.includes("bussiness") &&
                        <p className="text-sm text-gray-500 mt-2">
                          Business to Business contract?
                          <span className="ml-2 isolate inline-flex rounded-md shadow-sm">
                            <button
                              type="button"
                              className={`${interestModalStage == "bussiness_bussiness" && "bg-primary"} relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => {
                                setInterestModalStage("bussiness_bussiness");
                                setData({ 
                                  ...data, 
                                  interest_rate: (latePaymentsBaseRate ? latePaymentsBaseRate.rate : 0), // + (baseRate ? baseRate.rate : 0)
                                  interest_type: "bussiness_bussiness",
                                  invoice_interest_type: "late_payment"
                                });
                              }}
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className={`${interestModalStage == "bussiness_simple" && "bg-primary"} relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => {
                                setInterestModalStage("bussiness_simple");
                                setData({ 
                                  ...data, 
                                  interest_rate: 8,
                                  interest_type: "bussiness_simple",
                                  invoice_interest_type: "statutory"
                                });
                              }}
                            >
                              No
                            </button>
                          </span>
                        </p>}
                        {interestModalStage && (interestModalStage == "bussiness_simple") &&
                        <p className="text-sm text-gray-500 mt-2">
                          Simple interest rate at 8%  (s69 County Court Act 1984/s35a Senior Courts Act 1981)
                        </p>}
                        {interestModalStage && (interestModalStage == "bussiness_bussiness") &&
                        <p className="text-sm text-gray-500 mt-2">
                          Late Payment of Commerical Debts (Interest) Act applies (8% + Base): {(latePaymentsBaseRate && latePaymentsBaseRate.rate)} <br/>
                          Current Interest Base rate of Bank of England: {baseRate && baseRate.rate} <br/>
                          Calculated Rate: {data.interest_rate}
                        </p>}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                  <Button
                    label="Close"
                    colour="alert"
                    onClick={() => setOpenInterestModal(false)}
                  />
                </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  };

  const FeesDialog = () => {

    let currentTask = data.fixed_task ? tasks.filter(a => a.value == data.fixed_task) : [];

    return (
      <Transition
        show={feesModal}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        {showSelectSet && 
        <ConfirmModal
          handleClose={() => setShowSelectSet(false)}
          open={true}
          header={"Load Fee Set?"}
          content={
            "Are you sure you like to load this Fee Set, all fee values will be rest with this set values. This data is only saved once Fees is confirmed!"
          }
          handleSubmit={() => {
            let set = feeSets.find(set => set._id.toString() === data.fee_set);

            setData({ 
              ...data, 
              upfront_fee: set.upfront_fee,
              upfront_fee_type: set.upfront_fee_type,
              fee_thresholds: set.scale == "hourlyScale" ? clioUsers.map(c => {
                return {
                  _id: "custom_" + uuid(),
                  stage: -1,
                  fee: c.rate ? c.rate : 0,
                  original_fee: c.rate ? c.rate : 0,
                  threshold: "1",
                  over: false,
                  type: "fixed",
                  user: c.value,
                  scale: 'hourlyScale',
                }
              }) : set.thresholds,
              scale: set.scale
            });
            setShowSelectSet(false);
          }}
        /> }
        <Dialog
          open={feesModal}
          onClose={() => setFeesModal(false)}
          className="fixed z-9 inset-0 overflow-y-auto"
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className=" inline-block w-full max-w-5xl p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="text-xl">
                <Dialog.Title>
                  Edit Matter Fees
                </Dialog.Title>
              </div>
              <div className="flex flex-col px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                <div className="flex flex-row gap-2">
                  <label
                    htmlFor="upfront_fee"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Billable
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                        id="billable"
                        name="billable"
                        type="checkbox"
                        onChange={() => setData({ ...data, billable: !data.billable })}
                        checked={data.billable}
                        className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                    />
                  </div>
                </div>
                {data.billable && <div className="flex flex-row gap-2">
                  <label
                    htmlFor="upfront_fee"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Billing Method
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Dropdown
                      name={"billing_method"}
                      value={data.billing_method ? data.billing_method : ""}
                      onChange={(e) => {
                        setData({ 
                          ...data, 
                          billing_method: e.target.value,
                          scale: `${e.target.value}Scale`,
                          fee_thresholds: (e.target.value === "hourly" || e.target.value === "contingency") ? 
                            clioUsers.map(c => {
                              return {
                                _id: "custom_" + uuid(),
                                stage: -1,
                                fee: c.rate ? c.rate : 0,
                                original_fee: c.rate ? c.rate : 0,
                                threshold: "1",
                                over: false,
                                type: "fixed",
                                user: c.value,
                                scale: 'hourlyScale',
                              }
                            })
                          : []
                         });
                      }}
                      className={"inline-block mr-4"}
                      options={[
                        {key: 1, text: "Hourly", value: "hourly"},
                        {key: 2, text: "Fixed Fees", value: "fixed"},
                        {key: 3, text: "Contingency Fee", value: "contingency"},
                        {key: 4, text: "Fixed Fee Scale", value: "sliding"},
                        {key: 5, text: "Stage Fees", value: "stage"},
                      ]}
                    />
                    {data.billing_method === "contingency" && <>%
                    <Input
                      name="contingency_percentage"
                      value={data.contingency_percentage ? data.contingency_percentage : 0}
                      onChange={onChange}
                      className={"inline-block"}
                      type="number"
                      min="0"
                      // max="100"
                    /></>}

                    {/*  
                    {data.billing_method === "fixed" && <>£
                    <Input
                      name="fixed_amount"
                      value={data.fixed_amount ? data.fixed_amount : 0}
                      onChange={onChange}
                      className={"inline-block"}
                      type="number"
                      min="0"
                    /></>}
                    
                    {data.billing_method == "fixed" && <SelectZero 
                      label="Select Workflow Task to complete for Fixed Fee to be created"
                      name="fixed_task"
                      value={data.fixed_task}
                      onChange={onChange}
                      options={tasks}
                    
                    />}
                    {data.billing_method == "fixed" && <SelectZero 
                      disabled={currentTask && currentTask[0] ? (currentTask[0].steps.length > 0 ? false : true)  : true}
                      label="Select the Task's Step which the Fixed Fee to be created should be assigned to"
                      name="fixed_step"
                      value={data.fixed_step}
                      onChange={onChange}
                      options={currentTask && currentTask[0] ? (currentTask[0].steps.length > 0 ? currentTask[0].steps.map(s => ({text: s.title, value: s._id})) : [])  : []}
                    />}*/}
                  </div>
                </div>}
              </div>
              <div className="flex flex-col px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                <div className="flex flex-row gap-2">
                  <label
                    htmlFor="upfront_fee"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Upfront Fee (setup / LBA)
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    {(data.prev_upfront_fee_type != data.upfront_fee_type || data.prev_upfront_fee != data.upfront_fee) && <p className="bg-alert text-white p-2">Warning. The value has changed from which was set before, this will re-create the Setup Fee! - {data.prev_upfront_fee_type === "fixed" ? "£" : ""}{data.prev_upfront_fee}{data.prev_upfront_fee_type !== "fixed" ? "%" : ""}</p>}
                    <div className="inline-block">
                      <select
                          name="upfront_fee_type"
                          value={data.upfront_fee_type}
                          className="mt-1 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md bg-gray-200"
                          placeholder={"Select"}
                          onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                        >
                          <option value={"fixed"}>
                              £
                          </option>
                          <option value={"percent"}>
                              %
                          </option>
                      </select>
                    </div>
                    <div className="inline-block">
                      <input
                        type="number"
                        name="upfront_fee"
                        id="name"
                        value={data.upfront_fee}
                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                        className="w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:max-w-xs sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {data.billable && <>
                {data.scale == "slidingScale" &&
                <div className="flex flex-col px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                  <div className="flex flex-row gap-2">
                    <label
                      htmlFor="upfront_fee"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Load Set of Fees
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <select
                          name="fee_set"
                          value={data.fee_set}
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md bg-gray-200"
                          placeholder={"Select"}
                          onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                        >
                          <option disabled selected={data.fee_set == ""} hidden value="">Choose an option</option>
                          {feeSets.map((set, index) => { return <option key={index} value={set._id.toString()}>{set.name} ({set.scale})</option>})}
                        </select>
                        <Button
                          disabled={!data.fee_set}
                          colour="warning"
                          label="Load Set"
                          onClick={() => setShowSelectSet(true)}
                          icon={SaveAsIcon}
                        />
                    </div>
                  </div>
                </div>}
                <div className="flex flex-col px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                  <div className="flex flex-row gap-2">
                    <label
                      htmlFor="upfront_fee"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Rates:
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <RatesTable 
                        scale={data.scale} 
                        tasks={tasks} 
                        users={clioUsers} 
                        contingency={data.contingency_percentage} 
                        rates={data.fee_thresholds ? data.fee_thresholds.sort((a, b) =>
                          a.threshold > b.threshold ? 1 : a.threshold < b.threshold ? -1 : 0
                        ) : []} 
                      onCreate={(d) => {
                        let thresholds = data.fee_thresholds;

                        thresholds.push({
                          _id: "custom_" + uuid(),
                          fee : d.fee,
                          over_threshold : d.over,
                          threshold : d.threshold,
                          type : d.type,
                          stage : d.stage,
                          scale : d.scale,
                          user : d.user,
                          fixed_task: d.fixed_task,
                          fixed_step: d.fixed_step,
                        })

                        return setData({...data, fee_thresholds: thresholds})

                      }}
                      onUpdate={(id, d) => {
                        let thresholds = data.fee_thresholds;
                        let threshold = thresholds.find(item => item._id == id);

                        if(threshold) {
                          threshold.fee = d.fee;
                          threshold.over_threshold = d.over;
                          threshold.threshold = d.threshold;
                          threshold.type = d.type;
                          threshold.stage = d.stage;
                          threshold.scale = d.scale;
                          threshold.user = d.user;
                          threshold.fixed_task = d.fixed_task;
                          threshold.fixed_step = d.fixed_step;                          
                        }

                        return setData({...data, fee_thresholds: thresholds})

                      }}
                      onDelete={(id) => {
                        let thresholds = data.fee_thresholds;
                        let threshold = thresholds.findIndex(item => item._id == id);

                        if(threshold != -1){
                          thresholds.splice(threshold, 1);
                        }

                        return setData({...data, fee_thresholds: thresholds})
                      }} />
                    </div>
                    {data.scale === "stageScale" &&
                    <div className="mt-8 flex flex-col">
                      <table>
                        <thead>
                          <tr>
                            <th>Automation</th>
                            <th>Step</th>
                          </tr>
                        </thead>
                        <tbody>
                          {automations.map((a, index) => {
                            
                            let step = tasks.flatMap(t => t.steps.filter(s => s._id == a.step));
                            return <tr>
                            <td>{index+1}</td>
                            <td>{a.title ? a.title : (step && step[0] ? step[0].title : "N/A")}</td>
                          </tr>
                          })}
                        </tbody>
                      </table>
                    </div>}
                  </div>
                </div>
              </>}
              <div className="flex justify-between mt-4 border-t border-gray-300 pt-4">
                <Button
                  colour="alert"
                  label="Close"
                  onClick={() => setFeesModal(false)}
                  icon={XIcon}
                />
{/*  
                <Button
                  colour="green"
                  label="Confirm"
                  onClick={UpdateFees}
                  icon={CheckIcon}
                />*/}
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div className="pb-8">
      {interestModal()}
      {FeesDialog()}
      <Header title={"Editing Matter"} />
      <div className={"mt-4"}>
        <Segment>
          <div className="">
            <div className=" leading-6 font-medium text-xl text-gray-900">
              Matter Information.
            </div>
            <div className="w-full border-t border-gray-300 my-2" />
          </div>

          <div className="py-2">
            <TextArea
              label="Matter Description"
              name="description"
              value={data.description}
              onChange={onChange}
            />
          </div>
          <Group className="py-2">
            <Input
              label="Display Number (Generated from Clio)"
              name="display_number"
              value={data.display_number}
              onChange={onChange}
              disabled
            />
            <Input
              label="Client Reference Number"
              name="client_reference"
              value={data.client_reference}
              onChange={onChange}
            />
            {/*  
            <Input
              label="Location"
              name="location"
              value={data.location}
              onChange={onChange}
              />*/}
            <Input
              label="Payment Terms"
              name="paymentTerms"
              value={data.paymentTerms}
              onChange={onChange}
            />
            <Dropdown
              label={"Area of Law"}
              name={"practice_area"}
              value={data.practice_area ? data.practice_area : ""}
              onChange={(e) => {
                setData({ ...data, [e.target.name]: e.target.value, areaTask: ""  });
              }}
              options={practiceArea ? practiceArea : []}
            />
            <Dropdown
              // disabled={!data.practice_area !== "" || (areaTasks ? !areaTasks.filter(a => a.practice == data.practice_area).length > 0 : true)}
              label={"Workflow Tasks"}
              name={"areaTask"}
              value={data.areaTask ? data.areaTask : ""}
              onChange={onChange}
              options={areaTasks ? areaTasks.filter(a => a.practice == data.practice_area) : []}
            />
            <Dropdown
              label={"Status"}
              name={"status"}
              value={data.status ? data.status : ""}
              onChange={onChange}
              options={[
                {text: "Open", value: "Open"},
                {text: "On Hold", value: "On Hold"},
                {text: "Closed", value: "Closed"},
                {text: "Archived", value: "Archived"},
              ]}
            />
          </Group>
          <Group className="py-2">
            <SelectTwo
              label={"Fee Earner"}
              field={"responsible_attorney"}
              value={data.responsible_attorney ? data.responsible_attorney : ""}
              onChange={onChange}
              options={clioUsers}
            />
            <SelectTwo
              label={"Supervisor"}
              field={"originating_attorney"}
              value={data.originating_attorney ? data.originating_attorney : ""}
              onChange={onChange}
              options={clioUsers}
            />
            <div className="w-full">
              Current Interest Rate: {data.interest_rate}
              <Button
                colour="action"
                label="Interest Rate"
                onClick={() => setOpenInterestModal(true)}
                icon={GiMoneyStack}
              />
            </div>
            <Dropdown
              label={"Invoice Interest Type"}
              name={"invoice_interest_type"}
              value={data.invoice_interest_type ? data.invoice_interest_type : ""}
              onChange={onChange}
              // disabled={true}
              options={InterestTypes}
            />
            <Dropdown
              label={"Xero Invoices"}
              name={"xero_send"}
              value={data.xero_send ? data.xero_send : ""}
              onChange={onChange}
              options={[
                {key: 1, text: "Instant - Sends to Xero as it comes in", value: "instant"},
                {key: 2, text: "Manual - No Automatic sending", value: "manual"},
                {key: 3, text: "Weekly Joint - Joins Invoices together and sends weekly", value: "weekly_joint"},
                {key: 4, text: "Monthly Joint - Joins Invoices together and sends at end of month", value: "monthly_joint"},
              ]}
            />
            {/*  
            <Input
              label="Interest Rate"
              name="interest_rate"
              value={data.interest_rate}
              onChange={onChange}
            />*/}
          </Group>
          <div className="mt-5 divide-gray-300">
          <div className="">
            <h3 className=" leading-6 font-medium text-xl text-gray-900">
              Fee Rates
            </h3>
            <div className="w-full border-t border-gray-300 my-2" />
          </div>
          <div className="py-2">
            <dd className="my-auto text-sm text-gray-900">
              <span>
                <ul>
                  <li> Upfront Fee (setup / LBA): {" "}
                    {data.upfront_fee ? `${data.upfront_fee_type == "fixed" ? "£" : ""}${data.upfront_fee}${data.upfront_fee_type == "percent" ? "%" : ""}` : `Not Set!!`} 
                  </li>
                  {data.scale == "slidingScale" && "Sliding Scale for Success Fee:"}
                  {data.scale == "stageScale" && "Stage Scale for Success Fees:"}
                  {data.scale == "hourlyScale" && "Hourly Matter Fees:"}
                  {data.scale == "contingencyScale" && `Hourly Matter Fees with Contingency: (+${data.contingency_percentage}%)`}
                  {data.scale == "fixedScale" && `Fixed Matter Fees:`}
                  {/* {matter.scale == "fixedScale" && `Fixed Matter Fee: £${matter.fixed_amount} on Task: "${matter.fixed_task && matter.fixed_task.title}"`} */}
                  {data.fee_thresholds && data.fee_thresholds.length > 0 ? 
                    // data.fee_thresholds.filter(e => e.scale == data.scale).map((fee, index) => { 
                    data.fee_thresholds.map((fee, index) => { 
                      let user = clioUsers.filter(u => u.value == fee.user);

                      let fixed_task = fee.fixed_task ? tasks.filter(a => a.value == fee.fixed_task) : [];
                      let fixed_step = fixed_task[0] ? fixed_task[0].steps.filter(a => a._id == fee.fixed_step) : [];
                      
                      return <li key={index}>
                    - 
                    {fee.scale == "fixedScale" ? <>
                    Task: {fixed_task[0] ? fixed_task[0].text : ""} - Step: {fixed_step[0] ? fixed_step[0].title : ""} - Fee: £{fee.fee.toLocaleString()}
                    </> :
                    (fee.scale == "hourlyScale" || fee.scale == "contingencyScale") ? <>
                      {fee.user && user[0] && user[0].text} - Fee: £{(data.scale == "contingencyScale" ? ((parseFloat(fee.fee) * parseFloat(data.contingency_percentage / 100)) + parseFloat(fee.fee)).toFixed(2) : parseFloat(fee.fee)).toLocaleString()} per/hour {data.scale == "contingencyScale" ? `(£${fee.fee.toLocaleString()} + ${data.contingency_percentage}%)` : ""}
                    </> :
                    fee.scale == "stageScale" ? <>
                      Stage: {fee.stage} - Fee: £{fee.fee.toLocaleString()}
                    </> :
                    <>
                      {`${fee.over_threshold ? "Over:" : "Up to:"} £${fee.threshold.toLocaleString()}`} - Fee: {fee.type === "fixed" ? "£" : ""}{fee.fee.toLocaleString()}{fee.type === "percent" ? "%" : ""}
                    </>}

                  </li>}) : 
                    <li>No Threshold Fees!</li>}
                  {/* <li>Up to: £200 - £80 fee</li> */}
                </ul>
              </span>
            </dd>
            <dd>
              <Button
                onClick={() => {
                  setFeesModal(true);
                }}
                label="Set Fees"
                className="block ml-auto"
                colour="warning"
                icon={PencilIcon}
              />
            </dd>
          </div>
        </div>
          <div className="mt-5 divide-gray-300">
          <div className="">
            <h3 className=" leading-6 font-medium text-xl text-gray-900">
              Custom Fields
            </h3>
            <div className="w-full border-t border-gray-300 my-2" />
          </div>
          {/*  
          <div className="py-2">
            <TextArea
              label="Matter Description"
              name="description"
              value={data.description}
              onChange={onChange}
            />
          </div>*/}
          <div className="py-2">
            {customFields.length > 0 ? customFields.map((field) => {
            let field_name = field._id;
            // let field_name = (field.name).replace(" ", "_").toLowerCase();
              switch(field.fieldType.toLowerCase()){           
                case "date":
                  return <DateSelect name={field_name} onChange={(e) => setData({...data, custom_fields: {...data.custom_fields, [field_name]:e }})} value={data.custom_fields && data.custom_fields[field_name]} label={field.description} />
                case "time":
                  return <DateSelect timeOnly name={field_name} onChange={(e) => setData({...data, custom_fields: {...data.custom_fields, [field_name]:e }})} value={data.custom_fields && data.custom_fields[field_name]} label={field.description} />
                case "checkbox":
                    return  <div className="mt-5 relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        id={field_name}
                        aria-describedby="comments-description"
                        name={field_name}
                        type="checkbox"
                        onChange={(e) => {                      
                          setData({
                            ...data, 
                            custom_fields: 
                            {...data.custom_fields, 
                              [field_name]: e.target.checked
                            }
                          })
                        }}
                        checked={data.custom_fields && data.custom_fields[field_name]}
                        className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label htmlFor={field_name} className="font-medium text-gray-900">
                        {field.description}
                      </label>
                    </div>
                  </div>
                  case "email":
                    return <div>
                    <label htmlFor={field_name} className="block text-sm font-medium leading-6 text-gray-900">
                      {field.description}
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <input
                        type="email"
                        name={field_name}
                        id={field_name}
                        onChange={(e) => setData({...data, custom_fields: {...data.custom_fields, [field_name]:e.target.value }})} value={data.custom_fields && data.custom_fields[field_name]} 
                        className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                        placeholder="you@example.com"
                      />
                    </div>
                  </div>
                  case "currency":
                    return <div>
                    <label htmlFor={field_name} className="block text-sm font-medium leading-6 text-gray-900">
                      {field.description}
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <CurrencyPoundIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <input
                        type="number"
                        name={field_name}
                        id={field_name}
                        onChange={(e) => setData({...data, custom_fields: {...data.custom_fields, [field_name]:e.target.value }})} value={data.custom_fields && data.custom_fields[field_name]} 
                        className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  case "text_line":
                  case "textline":
                  case "url":
                  default:
                    return <div>
                    <label htmlFor={field_name} className="block text-sm font-medium leading-6 text-gray-900">
                      {field.description}
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name={field_name}
                        id={field_name}
                        onChange={(e) => setData({...data, custom_fields: {...data.custom_fields, [field_name]:e.target.value }})} value={data.custom_fields && data.custom_fields[field_name]} 
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  case "numeric":
                    return <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      {field.description}
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        name={field_name}
                        id={field_name}
                        onChange={(e) => setData({...data, custom_fields: {...data.custom_fields, [field_name]:e.target.value }})} value={data.custom_fields && data.custom_fields[field_name]} 
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  case "text_area":
                    return <div>
                    <label htmlFor={field_name} className="block text-sm font-medium leading-6 text-gray-900">
                      {field.description}
                    </label>
                    <div className="mt-2">
                      <textarea
                        rows={4}
                        name={field_name}
                        id={field_name}
                        onChange={(e) => setData({...data, custom_fields: {...data.custom_fields, [field_name]:e.target.value }})} value={data.custom_fields && data.custom_fields[field_name]} 
                        className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:py-1.5 sm:text-sm sm:leading-6"
                        defaultValue={''}
                      />
                    </div>
                  </div>
              }
            }) : "No Additonal Fields"}
          </div>
          <div className="pt-2 flex justify-between">
            <Button
              colour="alert"
              label="Cancel"
              onClick={() => history.push("/matters/" + matter._id)}
              icon={XIcon}
            />
            <Button
              colour="positive"
              label="Update"
              onClick={handleSubmit}
              icon={PencilIcon}
            />
          </div>
        </div>
          
        </Segment>
      </div>
    </div>
  );
}
export default ChainedContext(EditMatter, [
  [
    MatterReceiver,
    (matter) => {
      return { matter };
    },
  ],
]);
